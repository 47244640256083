import { Relation, useRide } from '@ridehub/data-ride';
import { useNavigate, useParams } from 'react-router-dom';

import React from 'react';
import { TopRelationsBox } from '@ridehub/ui-widgets';
import { useLocalization } from '@ridehub/feat-localization';

export function FeatTopRelations() {
    const { rideId, segmentId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);
    const navigate = useNavigate();
    const { localizeCurrency, localizeDistance, printWithLocalUnit } = useLocalization();

    if (error || isPending) {
        return null;
    }

    let consideredRelations = data.relations;
    if (segmentId) {
        const [fromStopUuid, toStopUuid] = segmentId.split('_');
        // Map stop uuids to the order in which the stop is traveled to
        const stopSequence: { [rideUuid: string]: number } = {};
        data.segments.forEach((segment, index) => {
            stopSequence[segment.from_uuid] = index;
            if (index === data.segments.length - 1) {
                stopSequence[segment.to_uuid] = index + 1;
            }
        });
        const lowerBound = stopSequence[fromStopUuid];
        const upperBound = stopSequence[toStopUuid];
        const crossingRelations = data.relations.filter((relation) => {
            return stopSequence[relation.from_uuid] <= lowerBound && stopSequence[relation.to_uuid] >= upperBound;
        });
        consideredRelations = crossingRelations;
    }

    interface TopRelation extends Relation {
        yield_localized: number | typeof NaN;
        demand_revenue_localized: number;
        revenue_localized: number;
    }
    // Calculate required data/KPIs
    const topRelations: TopRelation[] = consideredRelations
        .map((relation) => {
            // Often refered to as paxKm in Europe
            const paxDistance = relation.pax * localizeDistance(relation.distance_km);
            const revenue_localized = localizeCurrency(relation.revenue_eur, 'report');
            const yield_localized = (revenue_localized / paxDistance) * 100;
            const demand_revenue_localized =
                relation.remaining_demand_revenue_eur !== undefined
                    ? revenue_localized + localizeCurrency(relation.remaining_demand_revenue_eur, 'report')
                    : NaN;
            const topRelation = { ...relation, revenue_localized, yield_localized, demand_revenue_localized };
            return topRelation;
        })
        .filter((rel) => rel.demand_revenue_localized > 0);
    topRelations.sort((a, b) => b.demand_revenue_localized - a.demand_revenue_localized);
    const stopUuidToCode: { [key: string]: string } = {};
    data.segments.forEach((segment) => {
        stopUuidToCode[segment.from_uuid] = segment.from_stop_code;
        stopUuidToCode[segment.to_uuid] = segment.to_stop_code;
    });
    // Map to display format
    const numberOfShownRelations = 6;
    const topRelationsToDisplay = topRelations.slice(0, numberOfShownRelations).map((rel) => {
        return {
            fromUuid: rel.from_uuid,
            toUuid: rel.to_uuid,
            fromStopCode: stopUuidToCode[rel.from_uuid],
            toStopCode: stopUuidToCode[rel.to_uuid],
            revenue: printWithLocalUnit('currency', rel.revenue_localized, true, 'report', (val) => val === 0),
            yield: printWithLocalUnit('revenuePerDistance', rel.yield_localized, true, 'report'),
            demandRevenue: printWithLocalUnit('currency', rel.demand_revenue_localized, true, 'report'),
        };
    });
    function navigateToRelation(fromUuid: string, toUuid: string) {
        navigate(`/rides/${rideId}/relations/${fromUuid}_${toUuid}`);
    }

    return <TopRelationsBox topRelations={topRelationsToDisplay} onClickRelation={navigateToRelation} />;
}
