import {
    Brush,
    CartesianGrid,
    ComposedChart,
    Dot,
    Label,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import React, { useState } from 'react';
import { RouteChangeReferenceLines, X_AXIS_SIZING, useZoomPreBookingsAxis } from '../commons-chart/commons-chart';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@flixbus/honeycomb-react';
import { Segment } from '@ridehub/data-ride';
import { Temporal } from '@js-temporal/polyfill';
import chartStyles from '../ui-charts.module.scss';
import { printDate } from '@ridehub/util-temporal';
import styles from './ride-bid-prices-chart.module.scss';
import { useLocalization } from '@ridehub/feat-localization';

const TRACE_COLORS = {
    first: styles.first_color,
    rest: styles.rest_color,
    hovered: styles.hover_color,
};

interface ChartData {
    [key: string]: number | boolean;
    pre_booking_day: number;
    route_changed: boolean;
}

export interface RideBidsPriceChartProps {
    rideDeparture: Temporal.ZonedDateTime | undefined;
    segmentsData: Array<Segment> | undefined;
    fareMillReferenceLine: () => JSX.Element | null;
}

export function RideBidPricesChart({ rideDeparture, segmentsData, fareMillReferenceLine }: RideBidsPriceChartProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [hoveredSegment, setHoveredSegment] = useState<string | undefined>();
    const { selectedCurrency, localizeCurrency, localizeUnit, printWithLocalUnit } = useLocalization();
    const { chartData, dataKeys, maxBidPriceSegmentKey } = React.useMemo(
        function prepareChartData(): {
            chartData: ChartData[];
            dataKeys: string[];
            maxBidPriceSegmentKey: string | undefined;
        } {
            if (!segmentsData) {
                return { chartData: [], dataKeys: [], maxBidPriceSegmentKey: undefined };
            }

            const longestSegmentData = segmentsData.reduce((max, curr) => {
                if (curr.turbo_outputs && max.turbo_outputs && curr.turbo_outputs?.length > max.turbo_outputs?.length) {
                    max = curr;
                }
                return max;
            });

            if (!longestSegmentData.turbo_outputs) {
                return { chartData: [], dataKeys: [], maxBidPriceSegmentKey: undefined };
            }

            const chartData: ChartData[] = longestSegmentData.turbo_outputs
                .map((x) => ({
                    pre_booking_day: x.pre_booking_day,
                    route_changed: x.route_changed,
                }))
                .sort((a, b) => a.pre_booking_day - b.pre_booking_day);
            const dataKeys: string[] = [];
            const maxBidPrice: { key: undefined | string; value: number } = {
                key: undefined,
                value: 0.0,
            };

            segmentsData.forEach((segmentData, index) => {
                if (segmentData.turbo_outputs && segmentData.turbo_outputs.some((e) => e.bid_price_eur > 0)) {
                    const segmentName = `${index + 1}-${segmentData.from_stop_code}-${segmentData.to_stop_code}`;
                    dataKeys.push(segmentName);

                    const sortedSegmentData = segmentData.turbo_outputs.sort(
                        (a, b) => a.pre_booking_day - b.pre_booking_day
                    );
                    for (const dataPoint of sortedSegmentData) {
                        const dataDict: { [key: string]: number } = { pre_booking_day: dataPoint.pre_booking_day };
                        dataDict[segmentName] = localizeCurrency(dataPoint.bid_price_eur);
                        const chartDataIndex = chartData?.findIndex(
                            (r) => r.pre_booking_day === dataPoint.pre_booking_day
                        );
                        chartData[chartDataIndex] = { ...chartData[chartDataIndex], ...dataDict };

                        if (
                            dataPoint.pre_booking_day ==
                                sortedSegmentData[sortedSegmentData.length - 1].pre_booking_day &&
                            localizeCurrency(dataPoint.bid_price_eur) > maxBidPrice.value
                        ) {
                            maxBidPrice.key = segmentName;
                            maxBidPrice.value = localizeCurrency(dataPoint.bid_price_eur);
                        }
                    }
                }
            });

            return { chartData: chartData, dataKeys: dataKeys, maxBidPriceSegmentKey: maxBidPrice.key };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [segmentsData, selectedCurrency]
    );
    const { preBookingDayAxisEnd, preBookingDayAxisTicks, onPreBookingDayZoom } = useZoomPreBookingsAxis(
        chartData?.map((a) => a.pre_booking_day)
    );

    const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
        if (props.active && props.payload && props.payload.length) {
            const preBookingMinutes = -Math.round(24 * 60 * props.label);
            const optimizedAt =
                rideDeparture && printDate(rideDeparture.subtract({ minutes: preBookingMinutes }), false);
            const dataPoint = chartData?.find((r) => r.pre_booking_day === props.label);
            const lastPoint = chartData[chartData.length - 1];
            const isLastPoint = dataPoint?.pre_booking_day === lastPoint?.pre_booking_day;
            const isDepartedRide = lastPoint.pre_booking_day === 0;
            return (
                <Box extraClasses={chartStyles.tooltipContainer} highlighted>
                    {isLastPoint ? (
                        isDepartedRide ? (
                            <h2>At ride departure</h2>
                        ) : (
                            <h2>Now</h2>
                        )
                    ) : (
                        <h2>{`${optimizedAt} (${-props.label.toFixed(2)} days before departure)`}</h2>
                    )}
                    {props.payload.map((entry: any) => {
                        const isHovered = hoveredSegment === entry.dataKey;
                        return (
                            <p key={`tooltip-key-${entry.name}`} style={{ fontWeight: isHovered ? 'bold' : 'normal' }}>
                                {`${entry.dataKey}`}:{' '}
                                <span className={chartStyles.value}>{printWithLocalUnit('currency', entry.value)}</span>
                            </p>
                        );
                    })}
                    {dataPoint?.route_changed && (
                        <p>
                            <span className={chartStyles.value}>Route changed</span>
                        </p>
                    )}
                </Box>
            );
        }

        return null;
    };

    /**
     *
     * @param segmentKey concatenated stop codes as used in the chart and legend
     */
    function navigateToSegment(segmentKey: string) {
        const [, fromStopCode, toStopCode] = segmentKey.split('-');
        const selectedSegment = segmentsData?.find(
            (seg) => seg.from_stop_code === fromStopCode && seg.to_stop_code === toStopCode
        );
        if (selectedSegment) {
            navigate(`${location.pathname}/segments/${selectedSegment.from_uuid}_${selectedSegment.to_uuid}`);
        }
    }
    return (
        <Box extraClasses={chartStyles.chartContainer}>
            <h1>Segments&apos; Bid Prices</h1>
            <ResponsiveContainer width="100%" height={340}>
                <ComposedChart
                    className={chartStyles.chart}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 40,
                    }}
                >
                    <CartesianGrid className={chartStyles.cartesianGrid} vertical={false} />
                    <XAxis
                        dataKey="pre_booking_day"
                        type="number"
                        height={X_AXIS_SIZING.xAxisHeight}
                        domain={[(dataMin: number) => Math.floor(dataMin), preBookingDayAxisEnd]}
                        ticks={preBookingDayAxisTicks}
                        tickFormatter={(value: number) => (-value).toFixed(0)}
                    >
                        <Label value="Pre-booking day" dy={X_AXIS_SIZING.xAxisLabelDy} />
                    </XAxis>
                    <YAxis
                        orientation="left"
                        type="number"
                        domain={[0, (dataMax: number) => (Math.ceil(dataMax) === 0 ? 1 : Math.ceil(dataMax))]}
                        allowDecimals={false}
                    >
                        <Label
                            value={`Price (${localizeUnit('currency', true)})`}
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                            dx={0}
                            dy={0}
                        />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    {maxBidPriceSegmentKey && (
                        <Legend
                            onMouseEnter={(payload) => setHoveredSegment(payload.value)}
                            onMouseLeave={() => setHoveredSegment(undefined)}
                            onClick={(payload) => navigateToSegment(payload.value)}
                            data-dd-action-name="Select Segment in Chart Label"
                            align="right"
                            verticalAlign="top"
                            height={36}
                        />
                    )}
                    {fareMillReferenceLine()}
                    {RouteChangeReferenceLines({ routeChangesData: chartData })}
                    {dataKeys.map((dataKey) => {
                        const isHoveredSegment = hoveredSegment === dataKey;
                        const traceColor = dataKey === maxBidPriceSegmentKey ? TRACE_COLORS.first : TRACE_COLORS.rest;
                        return (
                            <Line
                                key={dataKey}
                                dataKey={dataKey}
                                strokeWidth={2}
                                onMouseEnter={() => setHoveredSegment(dataKey)}
                                onMouseLeave={() => setHoveredSegment(undefined)}
                                stroke={isHoveredSegment ? TRACE_COLORS.hovered : traceColor}
                                dot={
                                    <Dot
                                        onMouseEnter={() => setHoveredSegment(dataKey)}
                                        onMouseLeave={() => setHoveredSegment(undefined)}
                                    />
                                }
                                activeDot={
                                    <Dot
                                        onMouseEnter={() => setHoveredSegment(dataKey)}
                                        onMouseLeave={() => setHoveredSegment(undefined)}
                                    />
                                }
                            />
                        );
                    })}
                    <Brush
                        data-dd-action-name="Zoom in Chart Brush"
                        dataKey="pre_booking_day"
                        height={X_AXIS_SIZING.brushHeight}
                        tickFormatter={() => ''}
                        stroke={TRACE_COLORS.rest}
                        onChange={onPreBookingDayZoom as any}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
}
