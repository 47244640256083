import { Box, FooterNav, NavItem } from '@flixbus/honeycomb-react';
import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import React, { Fragment } from 'react';

import { Temporal } from '@js-temporal/polyfill';
import styles from './feat-ride-footer.module.scss';
import { useLocalization } from '@ridehub/feat-localization';
import { useParams } from 'react-router-dom';
import { useRide } from '@ridehub/data-ride';

export function FeatRideFooter() {
    const { rideId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);
    const { selectedCurrency, exchangeRateToEUR } = useLocalization();
    if (isPending || error) {
        return null;
    }

    const zonedArrivalOfFirstSegment = data.segments[0].arrival_time;
    const timeZone = zonedArrivalOfFirstSegment.getTimeZone();
    const now = Temporal.Now.instant();
    // There is not really a case this could be undefined according to docs, but types suggest
    // to handle it.
    const tzOffset = timeZone.getOffsetStringFor ? timeZone.getOffsetStringFor(now) : timeZone.id;

    function ExchangeRateInfo() {
        // This is the RMS base currency so we do not need to show applied exchange rates
        if (selectedCurrency === 'EUR') {
            return null;
        }
        return <span>Current exchange: 1 EUR = {exchangeRateToEUR}</span>;
    }
    return (
        <Box extraClasses={styles.footer}>
            <FooterNav aria-label="Footer-nav" horizontal>
                <NavItem
                    href="https://flixtech.atlassian.net/wiki/spaces/OPT/pages/232294683/RideHub+KPI+documentation+legend"
                    target="_blank"
                >
                    See full Legend{' '}
                    <Icon InlineIcon={IconNewTab} aria-label="open-new-tab" style={{ verticalAlign: 'sub' }} />
                </NavItem>
                <ExchangeRateInfo />
                <span className={styles.marginLeft}>All times are UTC {tzOffset}</span>
            </FooterNav>
        </Box>
    );
}
