import { useEffect } from 'react';

/**
 * @param callback function to be called once escape key is pressed \
 * Note: Should be used in combination with useCallback to avoid duplicate listeners
 */
export function useEscapeKey(callback: () => void) {
    useEffect(() => {
        function escapeHandler(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                callback();
            }
        }
        window.addEventListener('keydown', escapeHandler);
        return () => window.removeEventListener('keydown', escapeHandler);
    }, [callback]);
}
