import { createMockServer } from '@ridehub/data-ride';
import { msAzureADIdentityApi } from '../app/auth-config';
import { msGraphApiEndpoint } from '@ridehub/feat-authentication';

export function startMockApi(enableMockServer: boolean) {
    if (!enableMockServer) {
        return;
    }
    const config = {
        passthroughUrls: [`${msAzureADIdentityApi}/**`, msGraphApiEndpoint],
    };
    createMockServer(config);
}
