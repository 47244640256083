import React from 'react';
import { RideSegments } from './ride-segments/ride-segments';
import { useParams } from 'react-router-dom';

export function FeatSegmentsSidebar(props: {
    onSelectRelation?: (from?: string, to?: string) => void;
    onSelectSegment?: (segmentLabel?: string) => void;
}) {
    const { rideId, relationId, segmentId } = useParams();
    if (!rideId) {
        throw new Error('Ride UUID is required to load ride segments');
    }
    return (
        <RideSegments
            rideUuid={rideId}
            authenticate={true}
            onSelectSegment={props.onSelectSegment}
            onSelectRelation={props.onSelectRelation}
            defaultSegmentId={segmentId}
            defaultRelationId={relationId}
        />
    );
}
