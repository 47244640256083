import { FeatCurrencyPicker, LocalizationContextProvider } from '@ridehub/feat-localization';
import {
    FeatRelationKpiOverview,
    FeatRelationReport,
    FeatRideFooter,
    FeatRideKpiOverview,
    FeatRideReport,
    FeatSegmentReport,
    FeatTopRelations,
} from '@ridehub/feat-ride-report';
import { Login, Logout, UserProfile, requestProfile } from '@ridehub/feat-authentication';
import { MainWrapper, ThemeWrapper, themeDefault } from '@flixbus/honeycomb-react';
import { Navigate, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { ErrorBoundary } from '@ridehub/util-datadog';
import { FeatRideSearch } from '@ridehub/feat-ride-search';
import { FeatSegmentsSidebar } from '@ridehub/feat-segments-sidebar';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { RhHeader } from '@ridehub/ui-widgets';
import { appConfig } from '../utils/config.util';
import { create } from 'zustand';
import styles from './app.module.scss';
import { useApiConfig } from '@ridehub/data-ride';
import { useNavigate } from 'react-router-dom';

interface RHStore {
    userProfile: UserProfile | null;
    setUserProfile: (profile: UserProfile) => void;
    removeUserProfile: () => void;
}

export const useRHStore = create<RHStore>((set) => ({
    userProfile: null,
    setUserProfile: (profile: UserProfile) => set(() => ({ userProfile: profile })),
    removeUserProfile: () => set({ userProfile: null }),
}));

export function App() {
    const navigate = useNavigate();
    const userProfile = useRHStore((state) => state.userProfile);
    const setUserProfile = useRHStore((state) => state.setUserProfile);
    const themes = { default: themeDefault };
    const isAuthenticated = useIsAuthenticated();
    const apiConfig = useApiConfig();
    const { instance } = useMsal();

    function loadProfile() {
        async function setProfileWithMSData() {
            try {
                const profile = await requestProfile(instance, apiConfig.blankRedirectUri);
                setUserProfile(profile);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    navigate('/login');
                } else {
                    console.error('Error while fetching profile data', error);
                }
            }
        }
        if (isAuthenticated && !userProfile) {
            setProfileWithMSData();
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadProfile, [isAuthenticated]);

    interface ProtectedRouteProps {
        children: React.ReactNode;
    }

    function ProtectedRoute({ children }: ProtectedRouteProps): React.ReactNode {
        const isAuthenticated = useIsAuthenticated();
        const location = useLocation();
        if (!isAuthenticated) {
            if (location.pathname !== '/') {
                return <Navigate to={`/login?goTo=${location.pathname}`} replace />;
            } else {
                return <Navigate to="/login" replace />;
            }
        }

        return children;
    }

    function RideDashboard() {
        const location = useLocation();
        const { rideId, relationId, segmentId } = useParams();
        function navigateToSegment(nextSegmentId: string | undefined) {
            if (!nextSegmentId && (segmentId || relationId)) {
                navigate(`../..`, { relative: 'path' });
            } else if (!nextSegmentId) {
                return;
            } else if (segmentId) {
                navigate(`../${nextSegmentId}`, { relative: 'path' });
            } else if (relationId) {
                // go up two levels and out of relations view
                navigate(`../../segments/${nextSegmentId}`, { relative: 'path' });
            } else {
                navigate(`${location.pathname}/segments/${nextSegmentId}`);
            }
        }
        function navigateToRelation(from?: string, to?: string) {
            if (!from || !to) {
                return;
            } else if (relationId) {
                navigate(`../${from}_${to}`, { relative: 'path' });
            } else if (segmentId) {
                // go up two levels and out of segments view
                navigate(`../../relations/${from}_${to}`, { relative: 'path' });
            } else {
                navigate(`${location.pathname}/relations/${from}_${to}`);
            }
        }
        function navigateToRide() {
            navigate(`rides/${rideId}`);
        }
        const isRideView = !relationId && !segmentId;
        return (
            <div style={{ display: 'flex' }}>
                <div className={styles.sidebarLayout}>
                    <FeatRideKpiOverview onClick={navigateToRide} />
                    <FeatSegmentsSidebar onSelectRelation={navigateToRelation} onSelectSegment={navigateToSegment} />
                </div>
                <div className={styles.dashboardContainer}>
                    {isRideView && <RideDetails />}
                    <Outlet />
                    <FeatRideFooter />
                </div>
            </div>
        );
    }

    function RideDetails() {
        return (
            <div>
                <FeatTopRelations />
                <FeatRideReport />
            </div>
        );
    }

    function SegmentDetails() {
        return (
            <div>
                <FeatRelationKpiOverview />
                <FeatTopRelations />
                <FeatSegmentReport />
            </div>
        );
    }

    function RelationDetails() {
        return (
            <div>
                <FeatRelationKpiOverview />
                <FeatRelationReport />
            </div>
        );
    }

    return (
        <ThemeWrapper theme="default" themes={themes}>
            <MainWrapper full extraClasses={styles.appContainer}>
                <ErrorBoundary>
                    <LocalizationContextProvider>
                        <RhHeader
                            versionTag={appConfig.VITE_VERSION_TAG}
                            displayName={userProfile?.displayName}
                            countrySelect={<FeatCurrencyPicker />}
                        />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <FeatRideSearch onSeeRide={(rideId: string) => navigate(`rides/${rideId}`)} />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/rides/:rideId"
                                element={
                                    <ProtectedRoute>
                                        <RideDashboard />
                                    </ProtectedRoute>
                                }
                            >
                                <Route path="relations/:relationId" element={<RelationDetails />} />
                                <Route path="segments/:segmentId" element={<SegmentDetails />} />
                            </Route>
                            <Route
                                path="/login"
                                element={<Login userProfile={userProfile} setUserProfile={setUserProfile} />}
                            />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="*" element={<p>This link is not valid - 404!</p>} />
                        </Routes>
                    </LocalizationContextProvider>
                </ErrorBoundary>
            </MainWrapper>
        </ThemeWrapper>
    );
}

export default App;
