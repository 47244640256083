import { BackendError, CustomError, InputError, SearchError, UserInputError } from '@ridehub/data-ride';
import { Box, Button, Divider, Grid, GridCol, Skeleton, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconAttention, IconCheckmark, IconCopy, IconNewTab } from '@flixbus/honeycomb-icons-react';
import React, { useState } from 'react';

import { LabeledValue } from '../labeled-value/labeled-value';
import { Temporal } from '@js-temporal/polyfill';
import { printDate } from '@ridehub/util-temporal';
import styles from './ride-kpi-box.module.scss';

export function RideViewerLink(props: { rideId: string }) {
    const baseUrl = 'https://rideviewer.flixbus.com';
    const searchPath = 'ride-view/' + props.rideId + '/timeline';
    const rideViewerUrl = new URL(searchPath, baseUrl);
    return (
        <a href={rideViewerUrl.toString()} target="_blank" rel="noreferrer">
            RideViewer{' '}
            <Icon InlineIcon={IconNewTab} aria-label="open-new-tab" style={{ verticalAlign: 'sub' }} size={3} />
        </a>
    );
}

interface RideKpiBoxProps {
    rideId: string;
    from_stop: string;
    to_stop: string;
    line_code: string;
    trip_number: string;
    departure_time: Temporal.ZonedDateTime;
    revenue: string;
    avgLoad: number;
    yield: string;
    revenuePerUnitOfDistanceLabel: string;
    revenuePerUnitOfDistance: string;
    lastOptimization: Temporal.ZonedDateTime | undefined;
    isSelected: boolean;
    onClick: () => void;
}

export function RideKpiBoxPlaceholder() {
    return (
        <Box extraClasses={styles.container}>
            <Skeleton Elem="div" height="lg" />
            <Skeleton Elem="div" height="sm" />
            <Divider />
            <Skeleton style={{ marginTop: '5px' }} Elem="div" height="sm" />
            <Skeleton Elem="div" height="sm" />
        </Box>
    );
}

export function RideDataErrorBox(props: { error: CustomError; rideId?: string; extraClasses?: string }) {
    let userHint = 'This ride can not be shown at the moment. Please try again later.';
    let showRideViewerLink = false;
    if (props.error instanceof UserInputError) {
        userHint = 'The ride uuid you are looking for ist not valid. Please verify your search uuid and try again.';
    } else if (props.error instanceof BackendError) {
        userHint =
            'A Technical error occurred while searching for this ride.' +
            'The team was informed and will work to resolve this. In the ' +
            'meantime you may try again or search other rides.';
    } else if (props.error instanceof SearchError) {
        userHint = 'The ride you are looking for could not be found.';
    } else if (props.error instanceof InputError) {
        let rideViewerHint = '';
        if (props.rideId) {
            showRideViewerLink = true;
            rideViewerHint = 'For more info visit: ';
        }
        userHint =
            'The ride you are looking for contains segments with missing or overbooked capacities. ' + rideViewerHint;
    }
    const boxStyles = `${styles.container} ${styles.unclickable} ${props.extraClasses}`;
    return (
        <Box extraClasses={boxStyles}>
            <h1>
                <Icon InlineIcon={IconAttention} extraClasses={styles.errorIcon} />
                Error
            </h1>
            <p>{userHint}</p>
            {showRideViewerLink && props.rideId && <RideViewerLink rideId={props.rideId} />}
            <p>(Code {props.error.code})</p>
        </Box>
    );
}

export function RideKpiBox(props: RideKpiBoxProps) {
    const [hovered, setHovered] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopyTooltipOpen, setIsCopyTooltipOpen] = useState(false);

    function focus() {
        setHovered(true);
    }
    function unfocus() {
        setHovered(false);
    }

    function copyRideUuid() {
        navigator.clipboard.writeText(props.rideId);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    }

    let boxStyles = styles.container;
    if (props.isSelected) {
        boxStyles += ` ${styles.selectedState}`;
        return (
            <Box extraClasses={boxStyles} onMouseOver={focus} onMouseLeave={unfocus}>
                <Grid>
                    <GridCol size={11}>
                        <h1>
                            {props.from_stop} - {props.to_stop}
                        </h1>
                    </GridCol>
                    <GridCol size={1} extraClasses={styles.rightJustifiedCol}>
                        <Tooltip
                            id="copy-tooltip"
                            active={isCopyTooltipOpen}
                            content={isCopied ? 'Copied!' : 'Copy Ride UUID'}
                            position="left"
                            size="content-fit"
                        >
                            <Button
                                link
                                display="square"
                                size="sm"
                                onClick={copyRideUuid}
                                onMouseEnter={() => setIsCopyTooltipOpen(true)}
                                onMouseLeave={() => setIsCopyTooltipOpen(false)}
                            >
                                <Icon
                                    InlineIcon={isCopied ? IconCheckmark : IconCopy}
                                    size={2}
                                    aria-hidden="true"
                                    style={{ marginLeft: 0 }}
                                />
                            </Button>
                        </Tooltip>
                    </GridCol>
                    <GridCol size={12}>
                        <div className={styles.row}>
                            <p>
                                {props.line_code} {props.trip_number}
                            </p>
                            <p>{printDate(props.departure_time)}</p>
                        </div>
                    </GridCol>
                    <GridCol size={12}>
                        <Divider />
                    </GridCol>
                    <GridCol size={3}>
                        <LabeledValue label="Revenue" value={props.revenue} />
                    </GridCol>
                    <GridCol size={3}>
                        <LabeledValue label="Yield" value={props.yield} />
                    </GridCol>
                    <GridCol size={3}>
                        <LabeledValue
                            label={props.revenuePerUnitOfDistanceLabel}
                            value={props.revenuePerUnitOfDistance}
                        />
                    </GridCol>
                    <GridCol size={3}>
                        <LabeledValue label="Avg. Load" value={`${(props.avgLoad * 100).toFixed(0)}%`} />
                    </GridCol>
                    <GridCol size={9}>
                        <LabeledValue
                            label="Last optimization"
                            value={props.lastOptimization ? printDate(props.lastOptimization) : '--'}
                        />
                    </GridCol>
                    <GridCol size={3}>
                        <LabeledValue label="Status" value={'on_sale'.replace('_', ' ')} />
                    </GridCol>
                </Grid>
            </Box>
        );
    }
    if (hovered) {
        boxStyles += ` ${styles.hoveredState}`;
    }
    return (
        <Box
            extraClasses={boxStyles}
            onClick={props.onClick}
            onMouseOver={focus}
            onMouseLeave={unfocus}
            data-dd-action-name="Click Ride Kpi Box"
        >
            <Grid>
                <GridCol size={11}>
                    <h1>
                        {props.from_stop} - {props.to_stop}
                    </h1>
                </GridCol>
                <GridCol size={12}>
                    <div className={styles.row}>
                        <p>
                            {props.line_code} {props.trip_number}
                        </p>
                        <p>{printDate(props.departure_time)}</p>
                    </div>
                </GridCol>
                <GridCol size={12}>
                    <LabeledValue label="Avg. Load" value={`${(props.avgLoad * 100).toFixed(0)}%`} compact />
                </GridCol>
            </Grid>
        </Box>
    );
}
