import { CurrencyCode, DistanceMetric, currencies, currencyMap } from './currencies';
import {
    Flag,
    LanguageSwitcher,
    LanguageSwitcherPopup,
    LanguageSwitcherPopupItem,
    LanguageSwitcherPopupRegion,
    LanguageSwitcherToggle,
} from '@flixbus/honeycomb-react';
import React, { useState } from 'react';

import { useExchangeRates } from '@ridehub/data-ride';

export interface LocalizationCtx {
    selectedCurrency: CurrencyCode;
    selectCurrency: (cc: CurrencyCode) => void;
    exchangeRateToEUR: string;
    localizeCurrency: (value: number | undefined, context?: 'report' | 'country') => number;
    localizeDistance: (value: number) => number;
    localizeUnit: (
        kind: 'revenuePerDistance' | 'currency' | 'distance',
        useSymbol?: boolean,
        context?: 'report' | 'country'
    ) => string;
    printWithLocalUnit: (
        kind: 'distance' | 'currency' | 'revenuePerDistance',
        value?: number,
        useSymbol?: boolean,
        context?: 'report' | 'country',
        invalidCondition?: (val: number) => boolean
    ) => string;
    availableCurrencies: Set<CurrencyCode>;
}

export const LocalizationContext = React.createContext<LocalizationCtx | null>(null);

export function LocalizationContextProvider(props: { children: React.ReactNode; auth?: boolean }) {
    const authenticateRequests = props.auth === false ? false : true;
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCode>(getInitialSelectedCurrency());
    const { data, error } = useExchangeRates(true, authenticateRequests);
    const currentCountryExchangeRate = data?.find((rate) => rate.target_currency === selectedCurrency)?.exchange_rate;
    const currentReportExchangeRate =
        currencyMap[selectedCurrency].reportCurrency === CurrencyCode.EUR
            ? 1
            : data?.find((rate) => rate.target_currency === currencyMap[selectedCurrency].reportCurrency)
                  ?.exchange_rate;
    const availableCurrencies = new Set<CurrencyCode>([CurrencyCode.EUR]);

    if (data) {
        data.forEach((exchangeRate) => {
            // only add what should be visible to the user
            if (currencies.includes(exchangeRate.target_currency as CurrencyCode)) {
                availableCurrencies.add(exchangeRate.target_currency as CurrencyCode);
            }
        });
        if (!availableCurrencies.has(selectedCurrency)) {
            updateSelectedCurrency(CurrencyCode.EUR);
        }
    }

    if (error) {
        if (selectedCurrency !== CurrencyCode.EUR) {
            updateSelectedCurrency(CurrencyCode.EUR);
        }
    }

    function getInitialSelectedCurrency() {
        const storedValue = localStorage.getItem('selectedCurrency');
        if (storedValue && currencies.includes(storedValue as CurrencyCode)) {
            return storedValue as CurrencyCode;
        } else {
            // stored value is invalid
            localStorage.setItem('selectedCurrency', CurrencyCode.EUR);
            return CurrencyCode.EUR;
        }
    }

    function updateSelectedCurrency(currencyCode: CurrencyCode) {
        setSelectedCurrency(currencyCode);
        localStorage.setItem('selectedCurrency', currencyCode);
    }

    /**
     *
     * @param value - The value to be converted.
     * @param context - Specify the context in which the unit is used. Defaults to 'country'.
     * This will change, which currency is used for conversion.
     * @returns the value at the converted rate.
     */
    function localizeCurrency(value: number | undefined, context: 'report' | 'country' = 'country') {
        if (value === undefined) {
            return NaN;
        }
        if (selectedCurrency === CurrencyCode.EUR) {
            return value;
        }
        if (context === 'report') {
            if (currentReportExchangeRate === undefined) {
                return NaN;
            }
            return value * currentReportExchangeRate;
        } else {
            if (currentCountryExchangeRate === undefined) {
                return NaN;
            }
            return value * currentCountryExchangeRate;
        }
    }

    function localizeDistance(value: number) {
        switch (currencyMap[selectedCurrency].distanceMetric) {
            case DistanceMetric.km:
                return value;
            case DistanceMetric.mi:
                return value / 1.609344;
            default:
                throw new Error('Unknown distance metric');
        }
    }

    /**
     *
     * @param kind - Specify the kind of unit to be localized.
     * @param useSymbol - If true, will print $ instead of USD.
     * @param context - Specify the context in which the unit is used. Defaults to 'country'.
     * This will change, which currency is displayed.
     * @returns - A a string of the specified unit based on the localization.
     */
    function localizeUnit(
        kind: 'revenuePerDistance' | 'currency' | 'distance',
        useSymbol = false,
        context: 'report' | 'country' = 'country'
    ): string {
        switch (kind) {
            case 'revenuePerDistance':
                return useSymbol
                    ? `${localizeUnit('currency', useSymbol, context)}/${localizeUnit('distance', useSymbol, context)}`
                    : `${localizeUnit('currency', useSymbol, context)} ${localizeUnit('distance', useSymbol, context)}`;
            case 'currency': {
                let currency = currencyMap[selectedCurrency];
                if (context === 'report') {
                    currency = currencyMap[currency.reportCurrency];
                }
                return useSymbol ? currency.symbol : currency.code;
            }
            case 'distance':
                return currencyMap[selectedCurrency].distanceMetric;
            default:
                throw new Error('Unknown localization kind');
        }
    }

    /**
     * - Stringifies a value and appends the localized unit symbol (e.g. $ / €).
     * - Limits precision to 2 decimals for currency and 1 for distance.
     * - Falls back to a dash if value is invalid.
     * @param kind - Specify what kind of value is provided.
     * @param value - The value to be printed.
     * @param useSymbol - If true, will print $ instead of USD.
     * @param context - Specify the context in which the unit is used. Defaults to 'country'.
     * @param invalidCondition - A function that returns true if the value is invalid. Defaults to isNaN.
     */
    function printWithLocalUnit(
        kind: 'distance' | 'currency' | 'revenuePerDistance',
        value: number | undefined,
        useSymbol = true,
        context: 'report' | 'country' = 'country',
        invalidCondition: (val: number) => boolean = isNaN
    ) {
        const noValueChar = '-';
        if (value === undefined || invalidCondition(value)) {
            return noValueChar;
        }
        return `${value.toFixed(kind === 'distance' ? 1 : 2)} ${localizeUnit(kind, useSymbol, context)}`;
    }

    return (
        <LocalizationContext.Provider
            value={{
                selectedCurrency,
                selectCurrency: updateSelectedCurrency,
                exchangeRateToEUR: printWithLocalUnit('currency', currentCountryExchangeRate, false),
                localizeCurrency,
                localizeDistance,
                localizeUnit,
                printWithLocalUnit,
                availableCurrencies,
            }}
        >
            {props.children}
        </LocalizationContext.Provider>
    );
}

class OutsideContextError extends Error {}

export function useLocalization() {
    const context = React.useContext(LocalizationContext);
    if (!context) {
        throw new OutsideContextError('useLocalization must be used within a LocalizationContextProvider');
    }
    return context;
}

/**
 * This currency picker component allows selection of currency as users are used to from other
 * internal pricing tools such as PriceViewer.
 * The implementation however has a few subtleties:
 * - By design the currency selection is also used to changes distance metrics to
 * e.g. display miles/dollar instead of euro/km. So one almost picks a region.
 * - The picker component is based on the HC Language Switcher, because it is mostly the same UX,
 * but might be due to naming and usage of Flags.
 */
export function FeatCurrencyPicker() {
    const { selectedCurrency, selectCurrency: updateSelectedCurrency, availableCurrencies } = useLocalization();
    const closeBtnAriaLabel = 'Close currency selector.';

    function selectCurrency(currencyCode: CurrencyCode) {
        updateSelectedCurrency(currencyCode);
        // Programmatically close the popup as we do not navigate away
        const el = document.querySelector(`[aria-label="${closeBtnAriaLabel}"]`) as HTMLElement;
        el?.click();
    }

    return (
        <LanguageSwitcher>
            <LanguageSwitcherToggle
                aria-label="Select Currency"
                flag={
                    <Flag
                        countryCode={currencyMap[selectedCurrency].countryCode.toLowerCase()}
                        countryName={currencyMap[selectedCurrency].name}
                    />
                }
            >
                {currencyMap[selectedCurrency].code}
            </LanguageSwitcherToggle>
            <LanguageSwitcherPopup aria-label="Currency selector." closeLabel={closeBtnAriaLabel}>
                <LanguageSwitcherPopupRegion title="Most used">
                    {currencies
                        .filter((cc) => cc === CurrencyCode.EUR || cc === CurrencyCode.USD)
                        .filter((cc) => availableCurrencies.has(cc))
                        .map((currencyCode) => (
                            <LanguageSwitcherPopupItem
                                key={currencyCode}
                                active={currencyCode === selectedCurrency}
                                flag={
                                    <Flag
                                        countryCode={currencyMap[currencyCode].countryCode.toLowerCase()}
                                        countryName={currencyMap[currencyCode].countryCode}
                                    />
                                }
                                lang={currencyMap[currencyCode].name}
                                onClick={() => selectCurrency(currencyCode)}
                            >
                                {currencyMap[currencyCode].name}
                            </LanguageSwitcherPopupItem>
                        ))}
                </LanguageSwitcherPopupRegion>
                <LanguageSwitcherPopupRegion title="Other">
                    {currencies
                        .filter((cc) => cc !== CurrencyCode.EUR && cc !== CurrencyCode.USD)
                        .filter((cc) => availableCurrencies.has(cc))
                        .sort((a, b) => (currencyMap[a].name > currencyMap[b].name ? 1 : -1))
                        .map((currencyCode) => (
                            <LanguageSwitcherPopupItem
                                key={currencyCode}
                                active={currencyCode === selectedCurrency}
                                flag={
                                    <Flag
                                        countryCode={currencyMap[currencyCode].countryCode.toLowerCase()}
                                        countryName={currencyMap[currencyCode].countryCode}
                                    />
                                }
                                lang={currencyMap[currencyCode].name}
                                onClick={() => selectCurrency(currencyCode)}
                            >
                                {currencyMap[currencyCode].name}
                            </LanguageSwitcherPopupItem>
                        ))}
                </LanguageSwitcherPopupRegion>
            </LanguageSwitcherPopup>
        </LanguageSwitcher>
    );
}
