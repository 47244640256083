import { Button, ConnectionStop, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconMinus, IconPlus } from '@flixbus/honeycomb-icons-react';
import React, { Fragment, useState } from 'react';

import styles from './connection-stop.module.scss';

/**
 * This module contains a modified version of the ConnectionStop
 * from Honeycomb. It adds a button to select stops and modifies positioning.
 */

export function ConnectionStopWithButton(props: {
    key: string;
    station: string;
    stationName: string;
    time: string;
    current: boolean;
    selectStop: () => void;
    isLastSegment: boolean;
    selectable: boolean;
    minusButton: boolean;
    isHidden?: boolean;
    extraClasses?: string;
}) {
    const [hoveringStopName, setHoveringStopName] = useState<boolean>(false);
    const [hoveringButton, setHoveringButton] = useState<boolean>(false);
    const stopStyles = `${styles.connectionStop} ${props.extraClasses}`;
    const invisibleSeparatorUnicode = '⁣';
    const stationCode = props.isHidden ? invisibleSeparatorUnicode : props.station;

    const StopCodeWithTooltip = (
        <Tooltip
            id="tooltip-station"
            content={props.stationName}
            active={!props.isHidden && hoveringStopName}
            position="right"
        >
            <span>{stationCode}</span>
        </Tooltip>
    );

    return (
        <Fragment>
            {props.isLastSegment && props.selectable && (
                <div
                    onMouseOver={() => setHoveringButton(true)}
                    onMouseLeave={() => setHoveringButton(false)}
                    className={styles.scrollWithContent}
                >
                    <StopSelectButton
                        show={hoveringStopName || hoveringButton}
                        minusSymbol={props.minusButton}
                        onClick={props.selectStop}
                        positionBelow={true}
                        stopName={stationCode}
                    />
                </div>
            )}
            <ConnectionStop
                extraClasses={stopStyles}
                station={StopCodeWithTooltip}
                aria-label={stationCode}
                time={props.isHidden ? '' : props.time}
                current={props.current}
                onMouseOver={() => setHoveringStopName(true)}
                onMouseLeave={() => setHoveringStopName(false)}
            />
            {!props.isLastSegment && props.selectable && (
                <div
                    onMouseOver={() => setHoveringButton(true)}
                    onMouseLeave={() => setHoveringButton(false)}
                    className={styles.scrollWithContent}
                >
                    <StopSelectButton
                        show={hoveringStopName || hoveringButton}
                        minusSymbol={props.minusButton}
                        onClick={props.selectStop}
                        positionBelow={false}
                        stopName={stationCode}
                    />
                </div>
            )}
        </Fragment>
    );
}

export function StopSelectButton(props: {
    minusSymbol: boolean;
    show: boolean;
    onClick: () => void;
    positionBelow: boolean;
    stopName: string;
}) {
    if (!props.show) {
        return null;
    }
    let buttonStyles = `${styles.relationSelectBtn}`;
    if (props.positionBelow) {
        buttonStyles += ` ${styles.lowerPosition}`;
    }
    if (props.minusSymbol) {
        buttonStyles += ` ${styles.removeStops}`;
        return (
            <Button
                extraClasses={buttonStyles}
                display="square"
                aria-label={`Remove Stop ${props.stopName}`}
                onClick={props.onClick}
                data-dd-action-name="Deselect Stop"
            >
                <Icon InlineIcon={IconMinus} />
            </Button>
        );
    }
    buttonStyles += ` ${styles.addStops}`;
    return (
        <Button
            extraClasses={buttonStyles}
            display="square"
            aria-label={`Add Stop ${props.stopName}`}
            onClick={props.onClick}
            data-dd-action-name="Select Stop"
        >
            <Icon InlineIcon={IconPlus} />
        </Button>
    );
}
