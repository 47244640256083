import { Temporal } from '@js-temporal/polyfill';

export function printDate(dateTime: Temporal.ZonedDateTime | Temporal.Instant, include_weekend = true): string {
    const datePart = dateTime.toLocaleString(undefined, {
        weekday: include_weekend ? 'short' : undefined,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const timePart = dateTime.toLocaleString(undefined, {
        minute: '2-digit',
        hour: '2-digit',
    });
    return `${datePart} | ${timePart}`;
}
