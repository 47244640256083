import { BackendError, CustomError, InputError, SearchError, UserInputError } from './types/custom-errors';
import { ExchangeRate, RideDetails, RideSearchResult } from './api-interfaces';

import React from 'react';
import { transformRideData } from './utils/data-transform.utils';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';

export interface ApiConfig {
    azureAdApiId: string;
    ridehubApiUrl: string;
    blankRedirectUri: string;
}

const ApiConfigContext = React.createContext<ApiConfig | null>(null);

export function ApiContextProvider(props: { apiConfig: ApiConfig; children: React.ReactNode }) {
    if (!props.apiConfig) {
        return null;
    }
    return <ApiConfigContext.Provider value={props.apiConfig}>{props.children}</ApiConfigContext.Provider>;
}

export class OutsideContextError extends Error {}

export function useApiConfig() {
    const context = React.useContext(ApiConfigContext);
    if (!context) {
        throw new OutsideContextError('useApiConfig must be used within a ApiContextProvider');
    }
    return context as ApiConfig;
}

function useAuthToken() {
    const { instance, accounts } = useMsal();
    const apiConfig = useApiConfig();
    return async () => {
        await instance.initialize();
        const RIDES_API_SCOPE = `${apiConfig.azureAdApiId}/OAuth.Rides.Read`;
        const authResult = await instance.acquireTokenSilent({
            account: accounts[0],
            scopes: [RIDES_API_SCOPE],
            redirectUri: apiConfig.blankRedirectUri,
        });
        return authResult.accessToken;
    };
}

function buildAuthFetch(queryUrl: string, getAccessToken: () => Promise<string>) {
    return async (): Promise<Response> => {
        const accessToken = await getAccessToken();
        const authHeader = `Bearer ${accessToken}`;
        const headers = new Headers();
        headers.append('Authorization', authHeader);
        const options = {
            method: 'GET',
            headers: headers,
        };
        return fetch(queryUrl, options);
    };
}

/**
 * @param auth - Hack to disable obtaining a token while testing queries
 * TODO: Test the authentication logic as part of
 * https://jira.flix.tech/browse/OPT-1368
 * e.g. with https://github.com/Mimetis/msal-react-tester
 * @returns A fetch function that parses a JSON response and optionally transformed it
 */
function useJSONQuery(queryUrl: string, transformFn?: (param: unknown) => unknown, auth = true) {
    const getTokenFunc = useAuthToken();
    function parseAndTransform(res: Response) {
        if (res.ok) {
            return res.json().then((data) => (transformFn ? transformFn(data) : data));
        } else {
            switch (res.status) {
                case 422:
                    return Promise.reject(new UserInputError());
                case 404:
                    return Promise.reject(new SearchError());
                case 500:
                    return Promise.reject(new BackendError());
                case 400:
                    return Promise.reject(new InputError());
                default:
                    // These are unexpected responses - as in out of the api-contract defined above.
                    return res.text().then((respText) => Promise.reject(new CustomError(respText, res.status)));
            }
        }
    }
    let fetchFn = async () => fetch(queryUrl);
    if (auth) {
        fetchFn = buildAuthFetch(queryUrl, getTokenFunc);
    }
    return async () => fetchFn().then(parseAndTransform);
}

export function useRideSearch(rideId: string, auth = true) {
    const apiConfig = useApiConfig();
    const queryUrl = `${apiConfig.ridehubApiUrl}/v1/search?ride_uuid=${rideId}`;
    const queryFn = useJSONQuery(queryUrl, undefined, auth);
    return useQuery<boolean, CustomError, RideSearchResult>({
        queryKey: [queryUrl],
        queryFn,
        enabled: !!rideId,
        refetchOnMount: false,
        retry: false,
        staleTime: Number.POSITIVE_INFINITY,
    });
}

export function useExchangeRates(enabled = true, auth = true) {
    const apiConfig = useApiConfig();
    const rmsBaseCurrency = 'EUR';
    const queryUrl = `${apiConfig.ridehubApiUrl}/v1/exchange-rates?source_currency=${rmsBaseCurrency}`;
    const queryFn = useJSONQuery(queryUrl, undefined, auth);
    return useQuery<boolean, CustomError, Array<ExchangeRate>>({
        queryKey: [queryUrl],
        queryFn,
        refetchOnMount: false,
        retry: false,
        staleTime: 0,
        enabled,
    });
}

export function useRide(rideId?: string, auth = true) {
    const apiConfig = useApiConfig();
    const queryUrl = `${apiConfig.ridehubApiUrl}/v1/rides/${rideId}`;
    const queryFn = useJSONQuery(queryUrl, transformRideData, auth);
    return useQuery<boolean, CustomError, RideDetails>({
        queryKey: [queryUrl],
        queryFn: queryFn,
        enabled: !!rideId,
        refetchOnMount: false,
        retry: false,
        staleTime: 0,
    });
}

export { createMockServer } from './mock-server';
