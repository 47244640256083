import { Button, Heading, Infobox } from '@flixbus/honeycomb-react';

import React from 'react';
import { ReactNode } from 'react';
import { SLIContext } from './sli-context';
import { datadogRum } from '@datadog/browser-rum';

export interface IAppConfig {
    VITE_DATADOG_APPLICATION_ID: string;
    VITE_DATADOG_CLIENT_TOKEN: string;
    VITE_DATADOG_SITE: string;
    VITE_DATADOG_SERVICE: string;
    VITE_ENVIRONMENT: string;
    VITE_VERSION_TAG: string;
    MODE: string;
}

export function initDataDog(appConfig: IAppConfig) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (appConfig.MODE !== 'development' && !(window as any).Cypress) {
        datadogRum.init({
            applicationId: appConfig.VITE_DATADOG_APPLICATION_ID,
            clientToken: appConfig.VITE_DATADOG_CLIENT_TOKEN,
            site: appConfig.VITE_DATADOG_SITE,
            service: appConfig.VITE_DATADOG_SERVICE,
            env: appConfig.VITE_ENVIRONMENT,
            version: appConfig.VITE_VERSION_TAG,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
        });
        // Report handled cases
        return datadogRum.addError;
    } else {
        return console.error;
    }
}

/**
 * React and report unhandled rendering errors.
 * This can only be implemented as a class component at time of writing.
 */
export class ErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
    static contextType = SLIContext;
    declare context: React.ContextType<typeof SLIContext>;

    constructor(props: { children: ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: { componentStack: string }) {
        const renderingError = new Error(error.message);
        renderingError.name = `ReactRenderingError`;
        renderingError.stack = info.componentStack;
        // This is possible from es2022 on -> set a build target appropriatly
        // renderingError.cause = error;

        datadogRum.addError(renderingError);
        this.context?.reportSliBadEvent();
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Infobox appearance="danger" style={{ margin: '20px' }}>
                    <Heading size={2}>We encountered an issue and this should not have happened!</Heading>
                    <ul>
                        <li>You can reload RideHub to continue.</li>
                        <li>Team GreenRock has been informed.</li>
                    </ul>
                    <Button onClick={() => window.location.reload()}>Continue</Button>
                </Infobox>
            );
        }

        return this.props.children;
    }
}
