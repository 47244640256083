import * as ReactDOM from 'react-dom/client';

import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SLIContextProvider, initDataDog } from '@ridehub/util-datadog';

import { ApiContextProvider } from '@ridehub/data-ride';
import App from './app/app';
import { BackgroundAuth } from '@ridehub/feat-authentication';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { StrictMode } from 'react';
import { appConfig } from './utils/config.util';
import { startMockApi as mockApi } from './mock-api/mock-server';
import { msalConfig } from './app/auth-config';

mockApi(appConfig.VITE_BACKEND === 'mock');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const reportError = initDataDog({ ...appConfig, MODE: import.meta.env.MODE });
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            reportError(error, 'on query: ', query.queryKey);
        },
    }),
});

const apiConfig = {
    azureAdApiId: appConfig.VITE_AZURE_AD_API_ID,
    ridehubApiUrl: appConfig.VITE_API_URL,
    blankRedirectUri: `${appConfig.VITE_BASE_URL}/blank.html`,
};

root.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <ApiContextProvider apiConfig={apiConfig}>
                <BackgroundAuth msalConfig={msalConfig} onError={reportError}>
                    <BrowserRouter>
                        <SLIContextProvider>
                            <App />
                        </SLIContextProvider>
                    </BrowserRouter>
                </BackgroundAuth>
            </ApiContextProvider>
        </QueryClientProvider>
    </StrictMode>
);
