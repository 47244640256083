import React from 'react';
import { ReferenceLine } from 'recharts';
import { Tag } from '@flixbus/honeycomb-react';
import styles from './commons-chart.module.scss';

const FAREMILL_LABEL_REGEX = /[A-Z][0-9]{1,3}/;

export const PBP_BOUNDARIES = [
    -365, -91, -77, -63, -49, -42, -35, -28, -24, -21, -18, -16, -14, -12, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0,
];

export const X_AXIS_SIZING = {
    xAxisHeight: 40,
    xAxisLabelDy: 55,
    brushHeight: 20,
};

export interface ViewBox {
    x: number;
    y: number;
    height: number;
}

const InfoTag = (props: { info: string; viewBox: ViewBox }) => {
    return (
        <g>
            <foreignObject x={props.viewBox.x - 32} y={props.viewBox.y + props.viewBox.height} width={100} height={100}>
                <Tag display="subtle" small extraClasses={styles.infoTag}>
                    {props.info}
                </Tag>
            </foreignObject>
        </g>
    );
};

const CustomFareMillLabel = (props: { viewBox: ViewBox }) => {
    return <InfoTag info="FareMill" viewBox={props.viewBox} />;
};

export interface FareMillReferenceLineProps {
    fareLabelData?: { lowest_efficient_fare_label: string; pre_booking_day: number }[];
    yAxisId?: string;
    hideLabel?: boolean;
}

export function FareMillReferenceLine({ fareLabelData, yAxisId, hideLabel = false }: FareMillReferenceLineProps) {
    if (!fareLabelData) {
        return null;
    }

    let x = undefined;
    for (let i = fareLabelData.length - 1; i >= 0; i--) {
        if (fareLabelData[i].lowest_efficient_fare_label.match(FAREMILL_LABEL_REGEX)) {
            if (i !== fareLabelData.length - 1) {
                x = fareLabelData[i]?.pre_booking_day;
            }
            break;
        }
    }

    return <ReferenceLine x={x} yAxisId={yAxisId} label={!hideLabel && CustomFareMillLabel} />;
}

export interface RouteChangeReferenceLinesProps {
    routeChangesData?: { route_changed: boolean; pre_booking_day: number }[];
    yAxisId?: string;
}

export function RouteChangeReferenceLines({ routeChangesData, yAxisId }: RouteChangeReferenceLinesProps) {
    if (!routeChangesData) {
        return null;
    }

    const routeChangePreBookingDays = routeChangesData.filter((x) => x.route_changed).map((x) => x.pre_booking_day);

    return routeChangePreBookingDays.map((x, i) => (
        <ReferenceLine key={`route-change-line-${i}`} x={x} yAxisId={yAxisId} />
    ));
}

export function useZoomPreBookingsAxis(preBookingDays: Array<number> | undefined): {
    preBookingDayAxisEnd: number | string;
    preBookingDayAxisTicks: Array<number>;
    onPreBookingDayZoom: (newIndex: { startIndex: number; endIndex: number }) => void;
} {
    const sortedPBDs = React.useMemo(
        () => preBookingDays?.sort((a, b) => a - b),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(preBookingDays)]
    );

    const [preBookingDayAxisEnd, setPreBookingDayAxisEnd] = React.useState<number | string>(0);
    const [preBookingDayAxisTicks, setPreBookingDayAxisTicks] = React.useState(
        PBP_BOUNDARIES.filter((x) => sortedPBDs && x >= Math.round(sortedPBDs[0]))
    );

    const onPreBookingDayZoom = (newIndex: { startIndex: number; endIndex: number }) => {
        /*
            The expected behaviour when zooming on rides that are not departed,
            is to show the range from the earliest PBP till departure when zoomed out
            but jump to the next data point when adjusting the range from the right
        */
        if (sortedPBDs) {
            const startValue = Math.round(sortedPBDs[newIndex.startIndex]);
            const endValue = Math.round(sortedPBDs[newIndex.endIndex]);
            if (newIndex.endIndex === sortedPBDs.length - 1) {
                setPreBookingDayAxisEnd(0);
                setPreBookingDayAxisTicks(PBP_BOUNDARIES.filter((x) => x >= startValue));
            } else {
                setPreBookingDayAxisEnd('dataMax');
                setPreBookingDayAxisTicks(PBP_BOUNDARIES.filter((x) => x >= startValue && x <= endValue));
            }
        }
    };

    return {
        preBookingDayAxisEnd: preBookingDayAxisEnd,
        preBookingDayAxisTicks: preBookingDayAxisTicks,
        onPreBookingDayZoom: onPreBookingDayZoom,
    };
}
