import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { RouteChangeReferenceLines, X_AXIS_SIZING } from '../../commons-chart/commons-chart';

import { Box } from '@flixbus/honeycomb-react';
import React from 'react';
import { Temporal } from '@js-temporal/polyfill';
import { TurboOutputRelationLevel } from '@ridehub/data-ride';
import chartStyles from '../../ui-charts.module.scss';
import { printDate } from '@ridehub/util-temporal';
import styles from './turbo-price-chart.module.scss';
import { useLocalization } from '@ridehub/feat-localization';

const TRACE_COLORS = {
    primary: styles.primary_color,
    lowest_efficient_fare_color: styles.lowest_efficient_fare_color,
    bid_price_color: styles.bid_price_color,
    turbo_price: styles.turbo_price_color,
};

interface ChartDataPoint {
    pre_booking_day: number;
    route_changed: boolean;
    lowest_efficient_fare_localized: number;
    lowest_efficient_fare_label: string;
    bid_price_localized: number;
    turbo_price_localized: number;
}

export interface TurboPriceChartProps {
    rideDeparture: Temporal.ZonedDateTime | undefined;
    relationsData: Array<TurboOutputRelationLevel> | undefined;
    chartSyncId: string;
    preBookingDayAxisEnd: number | string;
    preBookingDayAxisTicks: Array<number>;
    fareMillReferenceLine: () => JSX.Element | null;
}

export function TurboPriceChart({
    rideDeparture,
    relationsData,
    chartSyncId,
    preBookingDayAxisEnd,
    preBookingDayAxisTicks,
    fareMillReferenceLine,
}: TurboPriceChartProps) {
    const { selectedCurrency, localizeUnit, localizeCurrency, printWithLocalUnit } = useLocalization();

    const { chartData } = React.useMemo(
        function prepareChartData(): {
            chartData: Array<ChartDataPoint>;
        } {
            if (!relationsData) {
                return { chartData: [] };
            }

            const chartData: Array<ChartDataPoint> = relationsData.map((entry) => {
                const dataPoint: ChartDataPoint = {
                    pre_booking_day: entry.pre_booking_day,
                    route_changed: entry.route_changed,
                    lowest_efficient_fare_localized: localizeCurrency(entry.lowest_efficient_fare_eur),
                    lowest_efficient_fare_label: entry.lowest_efficient_fare_label,
                    bid_price_localized: localizeCurrency(entry.bid_price_eur),
                    turbo_price_localized: localizeCurrency(entry.lowest_efficient_fare_eur + entry.bid_price_eur),
                };
                return dataPoint;
            });

            return { chartData: chartData.sort((a, b) => a.pre_booking_day - b.pre_booking_day) };
        },
        // Instances of the localize functions do not differ between renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [relationsData, selectedCurrency]
    );

    const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
        if (props.active && props.payload && props.payload.length) {
            const preBookingMinutes = -Math.round(24 * 60 * props.label);
            const optimizedAt =
                rideDeparture && printDate(rideDeparture.subtract({ minutes: preBookingMinutes }), false);
            const dataPoint = chartData?.find((r) => r.pre_booking_day === props.label);
            if (dataPoint === undefined || chartData === undefined) {
                return null;
            }
            const lastdataPoint = chartData[chartData.length - 1];
            const isLastDataPoint = dataPoint.pre_booking_day === lastdataPoint?.pre_booking_day;
            const isDepartedRide = lastdataPoint.pre_booking_day === 0;
            return (
                <Box extraClasses={chartStyles.tooltipContainer} highlighted>
                    {isLastDataPoint ? (
                        isDepartedRide ? (
                            <h2>At ride departure</h2>
                        ) : (
                            <h2>Now</h2>
                        )
                    ) : (
                        <h2>{`${optimizedAt} (${-props.label.toFixed(2)} days before departure)`}</h2>
                    )}
                    <p key={`tooltip-key-bid-price`}>
                        Turbo Price:{' '}
                        <span className={chartStyles.value}>
                            {printWithLocalUnit('currency', dataPoint?.turbo_price_localized)}
                        </span>
                    </p>
                    <p key={`tooltip-key-remaining-demand`}>
                        Lowest Efficient Class:{' '}
                        <span className={chartStyles.value}>{dataPoint?.lowest_efficient_fare_label}</span>
                    </p>
                    <p key={`tooltip-key-remaining-capacity`}>
                        Lowest Efficient Price:{' '}
                        <span className={chartStyles.value}>
                            {printWithLocalUnit('currency', dataPoint?.lowest_efficient_fare_localized)}
                        </span>
                    </p>
                    <p key={`tooltip-key-total-capacity`}>
                        Bid Price:{' '}
                        <span className={chartStyles.value}>
                            {printWithLocalUnit('currency', dataPoint?.bid_price_localized)}
                        </span>
                    </p>
                    {dataPoint?.route_changed && (
                        <p>
                            <span className={chartStyles.value}>Route changed</span>
                        </p>
                    )}
                </Box>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={290}>
            <ComposedChart
                className={chartStyles.chart}
                syncId={chartSyncId}
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 10,
                }}
            >
                <CartesianGrid className={chartStyles.cartesianGrid} vertical={false} />
                <XAxis
                    dataKey="pre_booking_day"
                    type="number"
                    height={X_AXIS_SIZING.xAxisHeight}
                    domain={[(dataMin: number) => Math.floor(dataMin), preBookingDayAxisEnd]}
                    ticks={preBookingDayAxisTicks}
                    tickFormatter={(value: number) => (-value).toFixed(0)}
                />
                <YAxis
                    orientation="left"
                    type="number"
                    domain={[0, (dataMax: number) => Math.ceil(dataMax)]}
                    allowDecimals={false}
                >
                    <Label
                        value={`Price (${localizeUnit('currency', true)})`}
                        angle={-90}
                        position="insideLeft"
                        offset={0}
                        dx={10}
                        dy={0}
                    />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                    align="right"
                    verticalAlign="top"
                    height={36}
                    payload={[
                        {
                            type: 'line',
                            value: 'Lowest Efficient Price',
                            color: `${TRACE_COLORS.lowest_efficient_fare_color}`,
                        },
                        { type: 'line', value: 'Bid Price', color: `${TRACE_COLORS.bid_price_color}` },
                        { type: 'square', value: 'Turbo Price', color: `${TRACE_COLORS.turbo_price}` },
                    ]}
                />
                {fareMillReferenceLine()}
                {RouteChangeReferenceLines({ routeChangesData: chartData })}
                <Area
                    dataKey="turbo_price_localized"
                    fill={TRACE_COLORS.turbo_price}
                    fillOpacity={0.3}
                    stroke={TRACE_COLORS.turbo_price}
                    strokeWidth={2}
                    dot={{
                        stroke: TRACE_COLORS.turbo_price,
                        strokeWidth: 2,
                        fill: 'white',
                        fillOpacity: 1,
                    }}
                />
                <Line
                    dataKey="lowest_efficient_fare_localized"
                    stroke={TRACE_COLORS.lowest_efficient_fare_color}
                    strokeWidth={2}
                />
                <Line dataKey="bid_price_localized" stroke={TRACE_COLORS.bid_price_color} strokeWidth={2} />
            </ComposedChart>
        </ResponsiveContainer>
    );
}
