/**
 * This is a list of currencies that are also supported in PriceViewer.
 * We try to keep this list in sync to have a uniform experience for users.
 */
export enum CurrencyCode {
    ALL = 'ALL',
    BAM = 'BAM',
    BGN = 'BGN',
    BRL = 'BRL',
    CAD = 'CAD',
    CHF = 'CHF',
    CLP = 'CLP',
    CZK = 'CZK',
    DKK = 'DKK',
    EUR = 'EUR',
    GBP = 'GBP',
    HUF = 'HUF',
    INR = 'INR',
    MKD = 'MKD',
    MXN = 'MXN',
    NOK = 'NOK',
    PLN = 'PLN',
    RON = 'RON',
    RSD = 'RSD',
    SEK = 'SEK',
    TRY = 'TRY',
    UAH = 'UAH',
    USD = 'USD',
}

export enum DistanceMetric {
    km = 'km',
    mi = 'mi',
}

type CurrencyProps = {
    code: CurrencyCode;
    name: string;
    symbol: string;
    countryCode: string;
    // In a pricing context with KPIs such as EUR/KM these belong together
    distanceMetric: DistanceMetric;
    reportCurrency: CurrencyCode;
};

/**
 * Map of all currencies above and their metadata.
 * This is thought to reflect all currencies we sell tickets in.
 * The distanceMetric and reportCurrency are used, because the local (customer facing)
 * currency might differ from the one used in KPIs and reports within the company.
 * Therefore both have to be used in different contexts within this app.
 */
export const currencyMap: Record<CurrencyCode, CurrencyProps> = {
    [CurrencyCode.ALL]: {
        code: CurrencyCode.ALL,
        name: 'Albanian Lek',
        symbol: 'ALL',
        countryCode: 'AL',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.BAM]: {
        code: CurrencyCode.BAM,
        name: 'Bosnia-Herzegovina Convertible Mark',
        symbol: 'KM',
        countryCode: 'BA',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.BGN]: {
        code: CurrencyCode.BGN,
        name: 'Bulgarian Lev',
        symbol: 'BGN',
        countryCode: 'BG',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.BRL]: {
        code: CurrencyCode.BRL,
        name: 'Brazilian Real',
        symbol: 'R$',
        countryCode: 'BR',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.CAD]: {
        code: CurrencyCode.CAD,
        name: 'Canadian Dollar',
        symbol: 'CA$',
        countryCode: 'CA',
        distanceMetric: DistanceMetric.mi,
        reportCurrency: CurrencyCode.USD,
    },
    [CurrencyCode.CHF]: {
        code: CurrencyCode.CHF,
        name: 'Swiss Franc',
        symbol: 'CHF',
        countryCode: 'CH',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.CLP]: {
        code: CurrencyCode.CLP,
        name: 'Chilean Peso',
        symbol: 'CLP',
        countryCode: 'CL',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.CZK]: {
        code: CurrencyCode.CZK,
        name: 'Czech Republic Koruna',
        symbol: 'CZK',
        countryCode: 'CZ',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.DKK]: {
        code: CurrencyCode.DKK,
        name: 'Danish Krone',
        symbol: 'DKK',
        countryCode: 'DK',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.EUR]: {
        code: CurrencyCode.EUR,
        name: 'Euro',
        symbol: '€',
        countryCode: 'EU',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.GBP]: {
        code: CurrencyCode.GBP,
        name: 'British Pound',
        symbol: '£',
        countryCode: 'GB',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.GBP,
    },
    [CurrencyCode.HUF]: {
        code: CurrencyCode.HUF,
        name: 'Hungarian Forint',
        symbol: 'HUF',
        countryCode: 'HU',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.INR]: {
        code: CurrencyCode.INR,
        name: 'Indian Rupee',
        symbol: '₹',
        countryCode: 'IN',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.MKD]: {
        code: CurrencyCode.MKD,
        name: 'Macedonian Denar',
        symbol: 'MKD',
        countryCode: 'MK',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.MXN]: {
        code: CurrencyCode.MXN,
        name: 'Mexican Peso',
        symbol: 'MX$',
        countryCode: 'MX',
        distanceMetric: DistanceMetric.mi,
        reportCurrency: CurrencyCode.USD,
    },
    [CurrencyCode.NOK]: {
        code: CurrencyCode.NOK,
        name: 'Norwegian Krone',
        symbol: 'NOK',
        countryCode: 'NO',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.PLN]: {
        code: CurrencyCode.PLN,
        name: 'Polish Zloty',
        symbol: 'PLN',
        countryCode: 'PL',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.RON]: {
        code: CurrencyCode.RON,
        name: 'Romanian Leu',
        symbol: 'RON',
        countryCode: 'RO',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.RSD]: {
        code: CurrencyCode.RSD,
        name: 'Serbian Dinar',
        symbol: 'RSD',
        countryCode: 'RS',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.SEK]: {
        code: CurrencyCode.SEK,
        name: 'Swedish Krona',
        symbol: 'SEK',
        countryCode: 'SE',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.TRY]: {
        code: CurrencyCode.TRY,
        name: 'Turkish Lira',
        symbol: 'TRY',
        countryCode: 'TR',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.UAH]: {
        code: CurrencyCode.UAH,
        name: 'Ukrainian Hryvnia',
        symbol: 'UAH',
        countryCode: 'UA',
        distanceMetric: DistanceMetric.km,
        reportCurrency: CurrencyCode.EUR,
    },
    [CurrencyCode.USD]: {
        code: CurrencyCode.USD,
        name: 'United States Dollar',
        symbol: '$',
        countryCode: 'US',
        distanceMetric: DistanceMetric.mi,
        reportCurrency: CurrencyCode.USD,
    },
};

export const currencies = Object.keys(currencyMap) as Array<CurrencyCode>;
