import {
    Area,
    Brush,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { RouteChangeReferenceLines, X_AXIS_SIZING, useZoomPreBookingsAxis } from '../commons-chart/commons-chart';

import { Box } from '@flixbus/honeycomb-react';
import React from 'react';
import { Temporal } from '@js-temporal/polyfill';
import { TurboOutputSegmentLevel } from '@ridehub/data-ride';
import chartStyles from '../ui-charts.module.scss';
import { printDate } from '@ridehub/util-temporal';
import styles from './segment-bid-price-chart.module.scss';
import { useLocalization } from '@ridehub/feat-localization';

const TRACE_COLORS = {
    primary: styles.primary_color,
    remaining_capacity: styles.remaining_capacity_color,
    remaining_forecast: styles.remaining_forecast_color,
    bid_price: styles.bid_price_color,
    brush: styles.brush_color,
    grid: styles.grid_color,
};

export interface SegmentBidPriceChartProps {
    rideDeparture: Temporal.ZonedDateTime | undefined;
    segmentData: Array<TurboOutputSegmentLevel> | undefined;
    fareMillReferenceLine: () => JSX.Element | null;
}

interface TurboOutputSegmentLevelLocalized extends TurboOutputSegmentLevel {
    bid_price_localized: number;
}

export function SegmentBidPriceChart({ rideDeparture, segmentData, fareMillReferenceLine }: SegmentBidPriceChartProps) {
    const { localizeUnit, localizeCurrency, printWithLocalUnit } = useLocalization();
    const { preBookingDayAxisEnd, preBookingDayAxisTicks, onPreBookingDayZoom } = useZoomPreBookingsAxis(
        segmentData?.map((a) => a.pre_booking_day)
    );

    const segmentDataLocalized = React.useMemo(
        function prepareChartData(): Array<TurboOutputSegmentLevelLocalized> | undefined {
            return segmentData
                ?.map((segment) => ({
                    ...segment,
                    bid_price_localized: localizeCurrency(segment.bid_price_eur),
                }))
                .sort((a, b) => a.pre_booking_day - b.pre_booking_day);
        },
        [segmentData, localizeCurrency]
    );

    const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
        if (props.active && props.payload && props.payload.length) {
            const preBookingMinutes = -Math.round(24 * 60 * props.label);
            const optimizedAt =
                rideDeparture && printDate(rideDeparture.subtract({ minutes: preBookingMinutes }), false);
            const dataPoint = segmentDataLocalized?.find((r) => r.pre_booking_day === props.label);
            if (dataPoint === undefined || segmentDataLocalized === undefined) {
                return null;
            }
            const lastdataPoint = segmentDataLocalized[segmentDataLocalized.length - 1];
            const isLastDataPoint = dataPoint.pre_booking_day === lastdataPoint?.pre_booking_day;
            const isDepartedRide = lastdataPoint.pre_booking_day === 0;
            return (
                <Box extraClasses={chartStyles.tooltipContainer} highlighted>
                    {isLastDataPoint ? (
                        isDepartedRide ? (
                            <h2>At ride departure</h2>
                        ) : (
                            <h2>Now</h2>
                        )
                    ) : (
                        <h2>{`${optimizedAt} (${-props.label.toFixed(2)} days before departure)`}</h2>
                    )}
                    <p key={`tooltip-key-bid-price`}>
                        Bid Price:{' '}
                        <span className={chartStyles.value}>
                            {printWithLocalUnit('currency', dataPoint.bid_price_localized)}
                        </span>
                    </p>
                    <p key={`tooltip-key-remaining-demand`}>
                        Remaining Demand:{' '}
                        <span className={chartStyles.value}>{dataPoint.remaining_forecast.toFixed(2)}</span> seats
                    </p>
                    <p key={`tooltip-key-remaining-capacity`}>
                        Remaining Capacity:{' '}
                        <span className={chartStyles.value}>{dataPoint.remaining_capacity.toFixed(0)}</span> seats
                    </p>
                    <p key={`tooltip-key-total-capacity`}>
                        Total Capacity: <span className={chartStyles.value}>{dataPoint.total_capacity.toFixed(0)}</span>{' '}
                        seats
                    </p>
                    {dataPoint.route_changed && (
                        <p>
                            <span className={chartStyles.value}>Route changed</span>
                        </p>
                    )}
                </Box>
            );
        }

        return null;
    };

    return (
        <Box extraClasses={chartStyles.chartContainer}>
            <h1>Bid Price vs. Remaining Demand</h1>
            <ResponsiveContainer width="100%" height={340}>
                <ComposedChart
                    className={chartStyles.chart}
                    data={segmentDataLocalized}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 40,
                    }}
                >
                    <CartesianGrid className={chartStyles.cartesianGrid} vertical={false} />
                    <XAxis
                        className={chartStyles.xAxis}
                        dataKey="pre_booking_day"
                        type="number"
                        height={X_AXIS_SIZING.xAxisHeight}
                        domain={[(dataMin: number) => Math.floor(dataMin), preBookingDayAxisEnd]}
                        ticks={preBookingDayAxisTicks}
                        tickFormatter={(value: number) => (-value).toFixed(0)}
                    >
                        <Label value="Prebooking day" dy={X_AXIS_SIZING.xAxisLabelDy} />
                    </XAxis>
                    <YAxis
                        yAxisId="left"
                        orientation="left"
                        type="number"
                        domain={[0, (dataMax: number) => Math.ceil(dataMax)]}
                        allowDecimals={false}
                    >
                        <Label value="Seats" angle={-90} position="insideLeft" offset={0} dx={10} dy={0} />
                    </YAxis>
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        type="number"
                        domain={[0, (dataMax: number) => (Math.ceil(dataMax) === 0 ? 1 : Math.ceil(dataMax))]}
                        allowDecimals={false}
                    >
                        <Label
                            value={`Price (${localizeUnit('currency', true)})`}
                            angle={-90}
                            position="right"
                            offset={0}
                            dx={-20}
                            dy={0}
                            fill={TRACE_COLORS.bid_price}
                        />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        align="right"
                        verticalAlign="top"
                        height={36}
                        payload={[
                            { type: 'line', value: 'Remaining Capacity', color: `${TRACE_COLORS.remaining_capacity}` },
                            {
                                type: 'line',
                                value: 'Remaining Demand',
                                color: `${TRACE_COLORS.remaining_forecast}`,
                            },
                            { type: 'square', value: 'Bid Price', color: `${TRACE_COLORS.bid_price}` },
                        ]}
                    />
                    {fareMillReferenceLine()}
                    {RouteChangeReferenceLines({ routeChangesData: segmentDataLocalized, yAxisId: 'left' })}
                    <Area
                        yAxisId="right"
                        dataKey="bid_price_localized"
                        fill={TRACE_COLORS.bid_price}
                        fillOpacity={0.2}
                        stroke={TRACE_COLORS.bid_price}
                        strokeWidth={2}
                    />
                    <Line
                        yAxisId="left"
                        dataKey="remaining_forecast"
                        stroke={TRACE_COLORS.remaining_forecast}
                        strokeWidth={2}
                    />
                    <Line
                        yAxisId="left"
                        dataKey="remaining_capacity"
                        stroke={TRACE_COLORS.remaining_capacity}
                        strokeWidth={2}
                    />
                    {/* The Brush component zooms on the data index, which does not
                        look visually consistent with the xAxis of type number.
                        There is a pending feature request in the library:
                        https://github.com/recharts/recharts/issues/3039*/}
                    <Brush
                        data-dd-action-name="Zoom in Chart Brush"
                        dataKey="pre_booking_day"
                        height={X_AXIS_SIZING.brushHeight}
                        tickFormatter={() => ''}
                        stroke={TRACE_COLORS.brush}
                        onChange={onPreBookingDayZoom as any}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
}
