import { BackendError, useRide } from '@ridehub/data-ride';
import {
    FareMillLink,
    PriceViewerLink,
    RelationKpiBox,
    RideDataErrorBox,
    RideKpiBox,
    RideKpiBoxPlaceholder,
    SegmentKpiBox,
} from '@ridehub/ui-widgets';
import { FareMillReferenceLine, RelationCharts, RideBidPricesChart, SegmentBidPriceChart } from '@ridehub/ui-charts';
import { useNavigate, useParams } from 'react-router-dom';

import React from 'react';
import { useLocalization } from '@ridehub/feat-localization';
import { useSLIContext } from '@ridehub/util-datadog';

export interface FeatRideKpiOverviewProps {
    onClick: () => void;
}

export function FeatRideKpiOverview(props: FeatRideKpiOverviewProps) {
    const { rideId, relationId, segmentId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);
    const { reportSliGoodEvent } = useSLIContext();
    const { localizeUnit, localizeCurrency, localizeDistance, printWithLocalUnit } = useLocalization();

    if (error) {
        if (!(error instanceof BackendError)) {
            // System worked as expected even though we display an error to the user
            reportSliGoodEvent();
        }
        return <RideDataErrorBox error={error} rideId={rideId} />;
    }
    if (isPending || !data || !rideId) {
        return <RideKpiBoxPlaceholder />;
    }

    const firstSegment = data.segments[0];
    const lastSegment = data.segments[data.segments.length - 1];
    const localizedRevenue = localizeCurrency(data.revenue_eur, 'report');
    const rideYield = (localizedRevenue / localizeDistance(data.pax_km)) * 100;
    const euroKm = localizedRevenue / localizeDistance(data.distance_km);
    const zoned = data.last_optimized_at
        ? data.last_optimized_at.toZonedDateTime({
              timeZone: firstSegment.departure_timezone,
              calendar: 'gregory',
          })
        : undefined;
    reportSliGoodEvent();
    return (
        <RideKpiBox
            rideId={rideId}
            from_stop={firstSegment.from_stop_name}
            to_stop={lastSegment.to_stop_name}
            line_code={data.line_code}
            trip_number={data.trip_number}
            departure_time={firstSegment.departure_time}
            revenue={printWithLocalUnit('currency', localizedRevenue, true, 'report')}
            avgLoad={data.curr_average_load}
            yield={printWithLocalUnit('revenuePerDistance', rideYield, true, 'report')}
            revenuePerUnitOfDistance={printWithLocalUnit('revenuePerDistance', euroKm, true, 'report')}
            revenuePerUnitOfDistanceLabel={localizeUnit('revenuePerDistance', false, 'report')}
            lastOptimization={zoned}
            isSelected={!relationId && !segmentId}
            onClick={props.onClick}
        />
    );
}

/* eslint-disable-next-line */
interface FeatRelationKpiOverviewProps {}

export function FeatRelationKpiOverview(props: FeatRelationKpiOverviewProps) {
    const { rideId, relationId, segmentId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);
    const navigate = useNavigate();
    const { localizeCurrency, localizeDistance, printWithLocalUnit } = useLocalization();

    function navigateToRide() {
        navigate(`/rides/${rideId}`);
    }
    if (error) {
        return null;
    }
    if (isPending) {
        return null;
    }
    if (relationId) {
        const [fromStopUuid, toStopUuid] = relationId.split('_');
        const relation = data.relations.find((r) => r.from_uuid === fromStopUuid && r.to_uuid === toStopUuid);
        const fromSegment = data.segments.find((s) => s.from_uuid === fromStopUuid);
        const toSegment = data.segments.find((s) => s.to_uuid === toStopUuid);
        const fromStopName = fromSegment?.from_stop_name;
        const toStopName = toSegment?.to_stop_name;
        if (!relation || !fromStopName || !toStopName) {
            // Wrong id in url or missing relation data
            return null;
        }
        const localizedDistance = localizeDistance(relation.distance_km);
        const localizedRevenue = localizeCurrency(relation.revenue_eur, 'report');
        const paxDistance = relation.pax * localizedDistance;
        const relationYieldLocalized = (localizedRevenue / paxDistance) * 100;
        const durationMin = fromSegment.departure_time.until(toSegment.arrival_time).total({ unit: 'minutes' });
        return (
            <RelationKpiBox
                fromStop={fromStopName}
                toStop={toStopName}
                fromCity={fromSegment.from_city_name}
                toCity={toSegment.to_city_name}
                maxLoad={relation.curr_max_load}
                demandMaxLoad={relation.max_demand_load}
                revenue={printWithLocalUnit('currency', localizedRevenue, true, 'report')}
                yield={printWithLocalUnit('revenuePerDistance', relationYieldLocalized, true, 'report')}
                distance={printWithLocalUnit('distance', localizedDistance, true, 'report')}
                durationMin={durationMin}
                onClose={navigateToRide}
                priceViewerLink={
                    <PriceViewerLink
                        fromCityUuid={fromSegment.from_city_uuid}
                        toCityUuid={toSegment.to_city_uuid}
                        fromCityName={fromSegment.from_city_name}
                        toCityName={toSegment.to_city_name}
                        departureDate={fromSegment.departure_time}
                        arrivalDate={toSegment.arrival_time}
                    />
                }
                fareMillLink={
                    <FareMillLink fromCityUuid={fromSegment.from_city_uuid} toCityUuid={toSegment.to_city_uuid} />
                }
            />
        );
    } else if (segmentId) {
        const [fromStopUuid] = segmentId.split('_');
        const segment = data.segments.find((s) => s.from_uuid === fromStopUuid);
        if (!segment) {
            // Wrong id in url - probably log to datadog
            return null;
        }
        const duration = segment.departure_time.until(segment.arrival_time).total({ unit: 'minutes' });
        return (
            <SegmentKpiBox
                fromStop={segment.from_stop_name}
                toStop={segment.to_stop_name}
                fromCity={segment.from_city_name}
                toCity={segment.to_city_name}
                load={segment.curr_load}
                demandLoad={segment.demand_load}
                distance={printWithLocalUnit('distance', localizeDistance(segment.distance_km))}
                durationMin={duration}
                onClose={navigateToRide}
            />
        );
    } else {
        return null;
    }
}

/* eslint-disable-next-line */
interface FeatRideReportProps {}

export function FeatRideReport(props: FeatRideReportProps) {
    const { rideId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);

    if (isPending || error) {
        return null;
    }

    if (data) {
        const rideDeparture = data?.segments[0].departure_time;
        const relation = data?.relations.find((r) => r.turbo_outputs);

        return (
            <RideBidPricesChart
                rideDeparture={rideDeparture}
                segmentsData={data.segments}
                fareMillReferenceLine={() =>
                    FareMillReferenceLine({
                        fareLabelData: relation?.turbo_outputs,
                    })
                }
            />
        );
    } else {
        return null;
    }
}

/* eslint-disable-next-line */
interface FeatRelationReportProps {}

export function FeatRelationReport(props: FeatRelationReportProps) {
    const { rideId, relationId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);

    if (isPending || error) {
        return null;
    }

    if (relationId) {
        const [fromStopUuid, toStopUuid] = relationId.split('_');
        const rideDeparture = data?.segments[0].departure_time;
        const relation = data?.relations.find((r) => r.from_uuid === fromStopUuid && r.to_uuid === toStopUuid);

        return <RelationCharts rideDeparture={rideDeparture} relationData={relation?.turbo_outputs} />;
    } else {
        return null;
    }
}

/* eslint-disable-next-line */
interface FeatSegmentReportProps {}

export function FeatSegmentReport(props: FeatSegmentReportProps) {
    const { rideId, segmentId } = useParams();
    const { isPending, error, data } = useRide(rideId, true);

    if (isPending || error) {
        return null;
    }

    if (segmentId) {
        const [fromStopUuid, toStopUuid] = segmentId.split('_');
        const rideDeparture = data?.segments[0].departure_time;
        const segment = data?.segments.find((r) => r.from_uuid === fromStopUuid && r.to_uuid === toStopUuid);
        const relation = data?.relations.find((r) => r.turbo_outputs);

        return (
            <SegmentBidPriceChart
                rideDeparture={rideDeparture}
                segmentData={segment?.turbo_outputs}
                fareMillReferenceLine={() =>
                    FareMillReferenceLine({
                        fareLabelData: relation?.turbo_outputs,
                        yAxisId: 'left',
                        hideLabel: false,
                    })
                }
            />
        );
    } else {
        return null;
    }
}
