import { Response, createServer } from 'miragejs';

export interface MockServerOptions {
    passthroughUrls?: string[];
    urlPrefix?: string;
}

export function createMockServer(options?: MockServerOptions) {
    const passthroughs = options?.passthroughUrls || [];
    return createServer({
        routes() {
            this.namespace = 'api-mock';
            passthroughs.forEach((val) => {
                this.passthrough(val);
            });
            if (options?.urlPrefix) {
                this.urlPrefix = options.urlPrefix;
            }
            this.get('/v1/search', (schema, request) => {
                if (request.queryParams.ride_uuid === 'd6300dda-707b-431f-bdef-5edbb2d4de54') {
                    return new Response(500, {}, { error: 'Server error' });
                } else if (request.queryParams.ride_uuid === 'a505dda-707b-431f-bdef-5edbb2d4de55') {
                    return new Response(501, {}, { error: 'Not implemented' });
                } else if (request.queryParams.ride_uuid === 'd6300dda-707b-431f-bdef-5edbb2d4de55') {
                    return new Response(404, {}, { error: 'Ride not found' });
                } else if (request.queryParams.ride_uuid === 'd6300dda-707b-431f-bdef-5edbb2d4de') {
                    return new Response(422, {}, { error: 'Malformed user input' });
                } else {
                    return {
                        ride_uuid: request.queryParams.ride_uuid,
                        exists: true,
                    };
                }
            });
            this.get('/v1/exchange-rates', (schema, request) => {
                const data = [
                    {
                        source_currency: 'EUR',
                        target_currency: 'CZK',
                        exchange_rate: 25.29,
                    },
                    {
                        source_currency: 'EUR',
                        target_currency: 'DKK',
                        exchange_rate: 7.4595,
                    },
                    {
                        source_currency: 'EUR',
                        target_currency: 'CLP',
                        exchange_rate: 1009.125,
                    },
                    {
                        source_currency: 'EUR',
                        target_currency: 'CAD',
                        exchange_rate: 1.4949,
                    },
                    {
                        source_currency: 'EUR',
                        target_currency: 'MKD',
                        exchange_rate: 61.5,
                    },
                    {
                        source_currency: 'EUR',
                        target_currency: 'USD',
                        exchange_rate: 1.0934,
                    },
                ];
                return data.filter((val) => {
                    const matchesSource =
                        !request.queryParams.source_currency ||
                        val.source_currency === request.queryParams.source_currency;
                    return matchesSource;
                });
            });
            this.get('/v1/rides/:ride_uuid', (schema, request) => {
                if (request.params.ride_uuid === 'fail') {
                    return new Response(422, {}, { error: 'Malformed user input' });
                }
                if (request.params.ride_uuid === 'a505dda-707b-431f-bdef-5edbb2d4de55') {
                    return new Response(501, {}, { error: 'Not implemented' });
                } else {
                    return {
                        ride_uuid: request.params.ride_uuid,
                        line_uuid: 'd88ceba7-132a-4aae-90cf-838a88421b41',
                        line_code: 'LM4',
                        trip_number: 'TN33',
                        status: 'on_sale',
                        distance_km: 2.0,
                        revenue_eur: 1.0,
                        curr_average_load: 1.0,
                        pax: 25,
                        pax_km: 10.0,
                        segments: [
                            {
                                from_uuid: '40dde3b8-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'HOL8',
                                to_stop_code: 'MID6',
                                to_uuid: '40d8f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_name: 'Hofoldingen 1',
                                to_stop_name: 'Stop 2',
                                from_city_name: 'Stuttgart',
                                to_city_name: 'Munich',
                                from_city_uuid: '55dde3b8-8646-11e6-9066-549f350fcb0c',
                                to_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T10:10:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T10:30:00.000',
                                arrival_timezone: 'Europe/Paris',
                                distance_km: 1.0,
                                curr_total_capacity: 45,
                                curr_load: 0.8,
                                demand_load: 1.0,
                                bid_price_active: true,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1.0,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                            {
                                from_uuid: '40d8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '80d8f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'MID6',
                                to_stop_code: 'MUC1',
                                from_stop_name: 'Stop 2',
                                to_stop_name: 'Stop 3',
                                to_city_name: 'Munich',
                                from_city_name: 'Munich',
                                from_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                to_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T10:40:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T10:55:00.000',
                                arrival_timezone: 'Europe/Paris',
                                distance_km: 1.0,
                                curr_total_capacity: 50,
                                curr_load: 0.4,
                                demand_load: 0.8,
                                bid_price_active: false,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                    {
                                        pre_booking_day: -1.2,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1.5,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                            {
                                from_uuid: '80d8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '10c8f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'MUC1',
                                to_stop_code: 'MUC2',
                                from_stop_name: 'Stop 3',
                                to_stop_name: 'Stop 4',
                                to_city_name: 'Munich',
                                from_city_name: 'Munich',
                                from_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                to_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T11:00:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T11:48:00.000',
                                arrival_timezone: 'Europe/Paris',
                                distance_km: 1.0,
                                curr_total_capacity: 50,
                                curr_load: 1.0,
                                demand_load: 1.0,
                                bid_price_active: false,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                            {
                                from_uuid: '10c8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '11b8f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'MUC2',
                                to_stop_code: 'MUC3',
                                from_stop_name: 'Stop 4',
                                to_stop_name: 'Stop 5',
                                to_city_name: 'Munich',
                                from_city_name: 'Munich',
                                from_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                to_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T12:05:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T12:37:00.000',
                                arrival_timezone: 'Europe/Paris',
                                distance_km: 1.0,
                                curr_total_capacity: 50,
                                curr_load: 1.0,
                                demand_load: 1.0,
                                bid_price_active: true,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                            {
                                from_uuid: '11b8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '99b8f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'MUC3',
                                to_stop_code: 'MUC4',
                                from_stop_name: 'Stop 5',
                                to_stop_name: 'Stop 6',
                                to_city_name: 'Munich',
                                from_city_name: 'Munich',
                                from_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                to_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T12:45:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T13:20:00.000',
                                arrival_timezone: 'Europe/Paris',
                                distance_km: 3.5,
                                curr_total_capacity: 50,
                                curr_load: 0.2,
                                demand_load: 0.4,
                                bid_price_active: false,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                            {
                                from_uuid: '99b8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '99c8f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'MUC4',
                                to_stop_code: 'MUC5',
                                from_stop_name: 'Stop 6',
                                to_stop_name: 'Stop 7',
                                to_city_name: 'Munich',
                                from_city_name: 'Munich',
                                from_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                to_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T13:30:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T14:08:00.000',
                                arrival_timezone: 'Europe/Paris',
                                distance_km: 1.0,
                                curr_total_capacity: 50,
                                curr_load: 1.0,
                                demand_load: 1.0,
                                bid_price_active: true,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                            {
                                from_uuid: '99c8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '99f0f682-8646-11e6-9066-549f350fcb0c',
                                from_stop_code: 'MUC5',
                                to_stop_code: 'LON',
                                from_stop_name: 'Stop 7',
                                to_stop_name: 'London Heathrow Airport',
                                to_city_name: 'London',
                                from_city_name: 'Munich',
                                from_city_uuid: '55d8f682-8646-11e6-9066-549f350fcb0d',
                                to_city_uuid: '69a8f682-8646-11e6-9066-549f350fcb0d',
                                departure_time: '2021-01-01T18:12:00.000',
                                departure_timezone: 'Europe/Paris',
                                arrival_time: '2021-01-01T20:44:00.000',
                                arrival_timezone: 'Europe/London',
                                distance_km: 1.0,
                                curr_total_capacity: 50,
                                curr_load: 0.5,
                                demand_load: 0.5,
                                bid_price_active: false,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast: 10,
                                        bid_price_eur: 1,
                                        remaining_capacity: 49,
                                        total_capacity: 50,
                                    },
                                ],
                            },
                        ],
                        relations: [
                            {
                                from_uuid: '99c8f682-8646-11e6-9066-549f350fcb0c',
                                to_uuid: '99f0f682-8646-11e6-9066-549f350fcb0c',
                                distance_km: 2.0,
                                revenue_eur: 1.0,
                                curr_max_load: 0.5,
                                pax: 25,
                                max_demand_load: 1.0,
                                remaining_demand_revenue_eur: 10.0,
                                turbo_outputs: [
                                    {
                                        pre_booking_day: -1.0,
                                        route_changed: false,
                                        remaining_forecast_per_price_label: [
                                            {
                                                price_label: 'B20',
                                                nominal_price_eur: 41.0,
                                                remaining_forecast: 44.0,
                                            },
                                            {
                                                price_label: 'B10',
                                                nominal_price_eur: 31.0,
                                                remaining_forecast: 34.0,
                                            },
                                        ],
                                        lowest_efficient_fare_eur: 1.0,
                                        lowest_efficient_fare_label: 'B10',
                                        bid_price_eur: 2.0,
                                        scaling_factor: 1.2,
                                    },
                                    {
                                        pre_booking_day: -1.2,
                                        route_changed: false,
                                        remaining_forecast_per_price_label: [
                                            {
                                                price_label: 'B20',
                                                nominal_price_eur: 41.0,
                                                remaining_forecast: 44.0,
                                            },
                                            {
                                                price_label: 'B10',
                                                nominal_price_eur: 31.0,
                                                remaining_forecast: 34.0,
                                            },
                                        ],
                                        lowest_efficient_fare_eur: 1.0,
                                        lowest_efficient_fare_label: 'B10',
                                        bid_price_eur: 2.0,
                                        scaling_factor: 3.4,
                                    },
                                ],
                            },
                        ],
                        last_optimized_at: '2024-01-01T00:00:00.000Z',
                    };
                }
            });
            this.get('/', () => {
                return { Hello: 'RideHub' };
            });
        },
    });
}
