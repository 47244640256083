import { FareMillReferenceLine, useZoomPreBookingsAxis } from '../commons-chart/commons-chart';

import { Box } from '@flixbus/honeycomb-react';
import { ForecastChart } from './forecast-chart/forecast-chart';
import React from 'react';
import { Temporal } from '@js-temporal/polyfill';
import { TurboOutputRelationLevel } from '@ridehub/data-ride';
import { TurboPriceChart } from './turbo-price-chart/turbo-price-chart';
import chartStyles from '../ui-charts.module.scss';

export interface RelationChartsProps {
    rideDeparture: Temporal.ZonedDateTime | undefined;
    relationData: Array<TurboOutputRelationLevel> | undefined;
}

export function RelationCharts({ rideDeparture, relationData }: RelationChartsProps) {
    const { preBookingDayAxisEnd, preBookingDayAxisTicks, onPreBookingDayZoom } = useZoomPreBookingsAxis(
        relationData?.map((a) => a.pre_booking_day)
    );

    return (
        <Box extraClasses={chartStyles.chartContainer}>
            <h1>Turbo offered price</h1>
            <TurboPriceChart
                rideDeparture={rideDeparture}
                relationsData={relationData}
                chartSyncId="relationReportId"
                preBookingDayAxisEnd={preBookingDayAxisEnd}
                preBookingDayAxisTicks={preBookingDayAxisTicks}
                fareMillReferenceLine={() =>
                    FareMillReferenceLine({
                        fareLabelData: relationData,
                        hideLabel: true,
                    })
                }
            />
            <h1>Remaining demand until departure</h1>
            <ForecastChart
                rideDeparture={rideDeparture}
                relationsData={relationData}
                chartSyncId="relationReportId"
                preBookingDayAxisEnd={preBookingDayAxisEnd}
                preBookingDayAxisTicks={preBookingDayAxisTicks}
                onPreBookingDayZoom={onPreBookingDayZoom}
                fareMillReferenceLine={() => FareMillReferenceLine({ fareLabelData: relationData })}
            />
        </Box>
    );
}
