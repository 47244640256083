import { Connection, ConnectionSlot } from '@flixbus/honeycomb-react';
import { ConnectionPlaceholder, SegmentBox } from '../segment-box/segment-box';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { ConnectionStopWithButton } from '../connection-stop/connection-stop';
import styles from './ride-segments.module.scss';
import { useEscapeKey } from '../utils/hooks';
import { useRide } from '@ridehub/data-ride';

export interface RideSegmentProps {
    rideUuid: string;
    onSelectRelation?: (from?: string, to?: string) => void;
    onSelectSegment?: (segmentLabel?: string) => void;
    authenticate?: boolean;
    defaultSegmentId?: string;
    defaultRelationId?: string;
}

export function RideSegments(props: RideSegmentProps) {
    const { isPending, error, data } = useRide(props.rideUuid, !!props.authenticate);
    const segmentRefs = useRef<Array<HTMLDivElement | null>>([]);
    function deriveInitialSegmentState(segmentId?: string) {
        if (!segmentId) {
            return undefined;
        }
        const segIdx = data?.segments.findIndex((segment) => {
            return `${segment.from_uuid}_${segment.to_uuid}` === segmentId;
        });
        return segIdx;
    }
    function deriveInitialRelationState(relationId?: string) {
        const initialState = {
            from: undefined,
            to: undefined,
        };
        if (!relationId) {
            return initialState;
        }
        const [from, to] = relationId.split('_');
        const fromIdx = data?.segments.findIndex((segment) => segment.from_uuid === from);
        let toIdx = data?.segments.findIndex((segment) => segment.from_uuid === to);
        if (toIdx === -1) {
            // this could be the last stop
            toIdx = data?.segments.findIndex((segment) => segment.to_uuid === to);
            if (toIdx !== undefined && toIdx !== -1) {
                // the last stop is after the found segment
                toIdx += 1;
            }
        }
        return { from: fromIdx, to: toIdx };
    }
    const [selectedSegment, setSelectedSegment] = useState<number | undefined>(
        deriveInitialSegmentState(props.defaultSegmentId)
    );
    const [selectedRelation, setSelectedRelation] = useState<{ from?: number; to?: number }>(
        deriveInitialRelationState(props.defaultRelationId)
    );
    useEffect(() => {
        // Update selection once we receive data - necessary because we might have
        // a selection already, but the data is not yet available.
        // This could be refactored to do data-fetching in a parent.
        const selectedSegmentIdx = deriveInitialSegmentState(props.defaultSegmentId);
        const selectedRelationIdxs = deriveInitialRelationState(props.defaultRelationId);
        setSelectedSegment(selectedSegmentIdx);
        setSelectedRelation(selectedRelationIdxs);
        if (selectedSegmentIdx) {
            segmentRefs.current[selectedSegmentIdx]?.scrollIntoView();
        }
        if (selectedRelationIdxs.from !== undefined) {
            segmentRefs.current[selectedRelationIdxs.from]?.scrollIntoView();
        }
        // We do not need updated versions of the functions above
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, props.defaultSegmentId, props.defaultRelationId]);

    function resetSelection() {
        const defaultSelection = { from: undefined, to: undefined };
        setSelectedRelation(defaultSelection);
        setSelectedSegment(undefined);
        if (props.onSelectRelation) {
            props.onSelectRelation(defaultSelection.from, defaultSelection.to);
        }
        if (props.onSelectSegment) {
            props.onSelectSegment(undefined);
        }
    }
    const stableResetSelection = useCallback(resetSelection, [props]);
    useEscapeKey(stableResetSelection);

    if (isPending) {
        return (
            <div className={styles.container}>
                <ConnectionPlaceholder />
            </div>
        );
    }
    if (error || !data?.segments) {
        return null;
    }
    const segmentsData = data.segments;

    function selectSegment(segmentIdx: number) {
        setSelectedSegment(segmentIdx);
        if (props.onSelectSegment) {
            const segmentDetails = segmentsData[segmentIdx];
            props.onSelectSegment(`${segmentDetails.from_uuid}_${segmentDetails.to_uuid}`);
        }
    }

    function selectRelation(stopIdx: number) {
        const currentSelection = selectedRelation;
        if (currentSelection.from !== undefined) {
            if (stopIdx >= currentSelection.from) {
                currentSelection.to = stopIdx;
            } else {
                if (selectedRelation.to === undefined) {
                    currentSelection.to = currentSelection.from;
                    currentSelection.from = stopIdx;
                } else {
                    currentSelection.from = stopIdx;
                }
            }
        } else {
            currentSelection.from = stopIdx;
            currentSelection.to = stopIdx;
        }
        setSelectedRelation({ ...currentSelection });
        // invoke callback if both stops are selected by the user
        if (props.onSelectRelation && currentSelection.from !== currentSelection.to) {
            const fromStopUuid =
                currentSelection.from !== undefined ? segmentsData[currentSelection.from].from_uuid : undefined;
            const toStopUuid = currentSelection.to ? segmentsData[currentSelection.to - 1].to_uuid : undefined;
            props.onSelectRelation(fromStopUuid, toStopUuid);
        }
    }

    function segmentsToJSX(segments: typeof segmentsData, idxOffset = 0) {
        function deriveBusIndicator(stopIdx: number) {
            if (selectedRelation.to === undefined) {
                return stopIdx - 1 === selectedRelation.from;
            } else {
                return stopIdx === selectedRelation.to;
            }
        }

        /**
         * @param stopIdx stop to display symbol for
         * @returns true if it should show a minus symbol
         */
        function shouldDisplayMinusButton(stopIdx: number) {
            if (selectedRelation.from === undefined) {
                return false;
            } else if (selectedRelation.to === undefined) {
                return stopIdx === selectedRelation.from;
            } else {
                return stopIdx <= selectedRelation.to && stopIdx >= selectedRelation.from;
            }
        }

        function isSegmentPartOfSelectedRelation(segmentIdx: number) {
            if (selectedRelation.from === undefined) {
                return false;
            } else if (selectedRelation.to === undefined) {
                return segmentIdx === selectedRelation.from;
            } else {
                return segmentIdx >= selectedRelation.from && segmentIdx < selectedRelation.to;
            }
        }

        const segmentsAsJSX = segments.map((segment, idx) => {
            const stopOneIdx = idx + idxOffset;
            const stopTwoIdx = idx + idxOffset + 1;
            const isSelectable = stopOneIdx !== selectedRelation.to && stopOneIdx !== selectedRelation.from;
            const segmentIsSelected = idx + idxOffset === selectedSegment;
            const segmentjsx = [
                <ConnectionStopWithButton
                    key={`${stopOneIdx}${selectedRelation.to}{selectedRelation.from}`}
                    station={segment.from_stop_code}
                    stationName={segment.from_stop_name}
                    time={segment.departure_time.toLocaleString(undefined, {
                        minute: '2-digit',
                        hour: '2-digit',
                    })}
                    current={deriveBusIndicator(stopOneIdx)}
                    selectStop={() => selectRelation(stopOneIdx)}
                    isLastSegment={false}
                    selectable={isSelectable}
                    minusButton={shouldDisplayMinusButton(stopOneIdx)}
                />,
                <ConnectionSlot key={`${stopOneIdx}-conn`} extraClasses={styles.connectionSlot}>
                    <SegmentBox
                        load={segment.curr_load}
                        demandLoad={segment.demand_load}
                        totalCapacity={segment.curr_total_capacity}
                        bidPriceActive={segment.bid_price_active}
                        segmentIdx={idx + idxOffset}
                        isSelected={isSegmentPartOfSelectedRelation(idx + idxOffset) || segmentIsSelected}
                        onClick={selectSegment}
                        segmentLabel={`${segment.from_stop_code}-${segment.to_stop_code}`}
                        innerRef={(el) => (segmentRefs.current[idx + idxOffset] = el)}
                    />
                </ConnectionSlot>,
            ];
            const isLastSegment = idx === segments.length - 1;
            if (isLastSegment) {
                // Due to the hack of combining two connection components
                // we have one duplicate stop to hide.
                const isRepeatedStop = connectionTwo.length > 0 && idxOffset === 0;
                const relationIsSelected = selectedRelation.to !== selectedRelation.from;
                let lastStopStyles = '';
                if (isRepeatedStop) {
                    lastStopStyles += styles.repeatedStop;
                }
                if (relationIsSelected) {
                    // Add the visual Gap between connections only once selection is made,
                    // because it is annoying if a button jumps under the cursor.
                    lastStopStyles += ` ${styles.connectionDistance}`;
                }
                segmentjsx.push(
                    <ConnectionStopWithButton
                        extraClasses={lastStopStyles}
                        key={`${stopTwoIdx}${selectedRelation.to}{selectedRelation.from}`}
                        station={segment.to_stop_code}
                        stationName={segment.to_stop_name}
                        time={segment.arrival_time.toLocaleString(undefined, {
                            minute: '2-digit',
                            hour: '2-digit',
                        })}
                        isHidden={isRepeatedStop}
                        current={selectedRelation.to === stopTwoIdx && !isRepeatedStop}
                        selectStop={() => selectRelation(stopTwoIdx)}
                        isLastSegment={true}
                        selectable={!isRepeatedStop && selectedRelation.to !== stopTwoIdx}
                        minusButton={shouldDisplayMinusButton(stopTwoIdx)}
                    />
                );
            }
            return segmentjsx;
        });
        // React can render nested arrays, but Honeycomb has restrictions on
        // the type of children a Connection component can have.
        const flattenedJSX = segmentsAsJSX.flatMap((val) => val);
        return <Fragment>{flattenedJSX.map((val) => val)}</Fragment>;
    }

    let connectionOne = segmentsData;
    let connectionTwo: typeof segmentsData = [];
    if (selectedRelation.from !== undefined && selectedRelation?.from > 0) {
        connectionOne = segmentsData.slice(0, selectedRelation.from);
        connectionTwo = segmentsData.slice(selectedRelation.from);
    }
    const markConnOneGreen = selectedRelation.from === 0;
    const markConnTwoGreen = selectedRelation.from !== undefined && selectedRelation.from > 0;
    return (
        <div className={styles.container}>
            <Connection appearance={markConnOneGreen ? 'success' : undefined}>
                {segmentsToJSX(connectionOne)}
            </Connection>
            <Connection appearance={markConnTwoGreen ? 'success' : undefined}>
                {segmentsToJSX(connectionTwo, selectedRelation.from)}
            </Connection>
        </div>
    );
}
