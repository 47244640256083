import {
    Box,
    Connection,
    ConnectionSlot,
    ConnectionStop,
    Grid,
    GridCol,
    Skeleton,
    Tag,
    Text,
} from '@flixbus/honeycomb-react';
import { Icon, IconFinance } from '@flixbus/honeycomb-icons-react';
import React, { useEffect, useState } from 'react';

import { LabeledValue } from '@ridehub/ui-widgets';
import styles from './segment-box.module.scss';

function CapacityBar(props: { load: number; demandLoad: number }) {
    return (
        <div className={styles.capacityBar}>
            <div className={styles.load} style={{ width: `${props.load}%` }} />
            <div className={styles.demandLoad} style={{ width: `${Math.min(props.demandLoad, 100)}%` }} />
        </div>
    );
}

export function ConnectionPlaceholder() {
    return (
        <Connection>
            <ConnectionStop station={'Loading Segments'} />
            <ConnectionSlot>
                <SegmentBoxPlaceholder />
            </ConnectionSlot>
            <ConnectionStop station={''} />
        </Connection>
    );
}

export function SegmentBoxPlaceholder() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            } else {
                clearInterval(interval);
            }
        }, 20);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    return (
        <Box extraClasses={styles.segmentBox} small aria-label={`Loading Segment`}>
            <CapacityBar load={progress} demandLoad={progress} />
            <Grid extraClasses={styles.kpiContainer}>
                <GridCol size={8}>
                    <Skeleton Elem="div" height="sm" extraClasses={styles.topMargin1} />
                </GridCol>
            </Grid>
        </Box>
    );
}

export function SegmentBox(props: {
    segmentIdx: number;
    onClick: (segmentIdx: number) => void;
    isSelected: boolean;
    // KPIs
    load: number;
    demandLoad?: number;
    totalCapacity: number;
    bidPriceActive?: boolean;
    segmentLabel: string;
    innerRef?: React.Ref<HTMLDivElement>;
}) {
    const [hovered, setHovered] = useState(false);
    const loadPercentage = props.load * 100;
    const demandLoadPercentage = props.demandLoad ? props.demandLoad * 100 : 0;
    const bookedCapacity = Math.trunc(props.load * props.totalCapacity);
    function selectElement() {
        props.onClick(props.segmentIdx);
    }
    function unfocus() {
        setHovered(false);
    }
    function focus() {
        setHovered(true);
    }
    const boxStyles = `${styles.segmentBox} ${
        props.isSelected ? styles.selectedColor : hovered ? styles.hoveredColor : styles.deselectedColor
    }`;
    function formatCondPercentage(value: number | undefined): string {
        return value === null || value === undefined ? '--' : `${(value * 100).toFixed(0)}%`;
    }
    return (
        <Box
            extraClasses={boxStyles}
            small
            onMouseOver={focus}
            onMouseLeave={unfocus}
            onClick={selectElement}
            data-dd-action-name="Select Segment"
            aria-label={`Segment ${props.segmentLabel}`}
            innerRef={props.innerRef}
        >
            <CapacityBar load={loadPercentage} demandLoad={demandLoadPercentage} />
            {!props.isSelected ? (
                <Grid extraClasses={styles.kpiContainer} align="center">
                    <GridCol size={3}>
                        <LabeledValue compact size="sm" label="Load" value={formatCondPercentage(props.load)} />
                    </GridCol>
                    <GridCol size={5}>
                        <LabeledValue
                            compact
                            size="sm"
                            label="Demand Load"
                            value={formatCondPercentage(props.demandLoad)}
                        />
                    </GridCol>
                    <GridCol size={4} extraClasses={styles.pushRight}>
                        <Tag
                            display="outlined"
                            small
                            extraClasses={props.bidPriceActive ? styles.bidPriceTag : styles.hidden}
                            aria-label={`Segment ${props.segmentLabel} Bid Price Active`}
                        >
                            <Icon extraClasses={styles.priceTagIcon} InlineIcon={IconFinance} />
                        </Tag>
                    </GridCol>
                </Grid>
            ) : (
                <Grid extraClasses={styles.kpiContainer} align="center">
                    <GridCol size={3}>
                        <LabeledValue size="sm" label="Load" value={`${loadPercentage.toFixed(0)}%`} />
                    </GridCol>
                    <GridCol size={5}>
                        <LabeledValue size="sm" label="Demand Load" value={formatCondPercentage(props.demandLoad)} />
                    </GridCol>
                    <GridCol size={4}>
                        <LabeledValue size="sm" label="Capacity" value={`${bookedCapacity}/${props.totalCapacity}`} />
                    </GridCol>
                    {props.bidPriceActive && (
                        <GridCol size={4}>
                            <LabeledValue
                                size="sm"
                                label="Bid Price"
                                value={
                                    <div className={styles.priceTag}>
                                        <Icon InlineIcon={IconFinance} extraClasses={styles.priceTagIcon} />
                                        <Text extraClasses={styles.priceTagText}>Active</Text>
                                    </div>
                                }
                            />
                        </GridCol>
                    )}
                </Grid>
            )}
        </Box>
    );
}
