import { Box, Grid, GridCol, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconArrowBigRight, IconInfo } from '@flixbus/honeycomb-icons-react';

import React from 'react';
import styles from './top-relations-box.module.scss';

interface TopRelationDisplay {
    fromUuid: string;
    toUuid: string;
    fromStopCode: string;
    toStopCode: string;
    revenue: string;
    yield: string;
    demandRevenue: string;
}

interface TopRelationsBoxProps {
    topRelations: TopRelationDisplay[];
    onClickRelation: (fromUuid: string, toUuid: string) => void;
}

export function TopRelationsBox(props: TopRelationsBoxProps) {
    function RelationRow(props: { relation: TopRelationDisplay; onClick: (fromUuid: string, toUuid: string) => void }) {
        return (
            <Box
                extraClasses={styles.relationRow}
                data-dd-action-name="Select Top Relation"
                onClick={() => props.onClick(props.relation.fromUuid, props.relation.toUuid)}
            >
                <Grid>
                    <GridCol size={4}>
                        <p>
                            {props.relation.fromStopCode}{' '}
                            <Icon InlineIcon={IconArrowBigRight} extraClasses={styles.arrowIcon} />{' '}
                            {props.relation.toStopCode}
                        </p>
                    </GridCol>
                    <GridCol size={3}>
                        <p>{props.relation.demandRevenue}</p>
                    </GridCol>
                    <GridCol size={3}>
                        <p>{props.relation.revenue}</p>
                    </GridCol>
                    <GridCol size={2}>
                        <p>{props.relation.yield}</p>
                    </GridCol>
                </Grid>
            </Box>
        );
    }

    function RelationHeader() {
        const revenueDef = 'Sum of revenues of all tickets sold until now.';
        const demandRevenueDef =
            'Revenue of forecasted seats sold in the remaining pre-booking period until departure, added to revenue to date.';
        return (
            <Box extraClasses={styles.relationHeader}>
                <Grid>
                    <GridCol size={4}>
                        <h3>Relation</h3>
                    </GridCol>
                    <GridCol size={3}>
                        <Tooltip id="tooltip-rdr" content={demandRevenueDef} openOnHover smartPosition>
                            <h3>
                                Demand Revenue <Icon InlineIcon={IconInfo} size={2} />
                            </h3>
                        </Tooltip>
                    </GridCol>
                    <GridCol size={3}>
                        <Tooltip id="tooltip-rev" content={revenueDef} openOnHover smartPosition>
                            <h3>
                                Revenue <Icon InlineIcon={IconInfo} size={2} />
                            </h3>
                        </Tooltip>
                    </GridCol>
                    <GridCol size={2}>
                        <h3>Yield</h3>
                    </GridCol>
                </Grid>
            </Box>
        );
    }
    const numItemsInLeftColumn =
        props.topRelations.length % 2 === 0 ? props.topRelations.length / 2 : props.topRelations.length / 2 + 1;
    const leftHalf = props.topRelations.slice(0, numItemsInLeftColumn);
    const rightHalf = props.topRelations.slice(numItemsInLeftColumn);
    if (leftHalf.length === 0) {
        return null;
    }
    return (
        <Box extraClasses={styles.ridehubWidget}>
            <h2>Top performing relations</h2>
            <Grid>
                <GridCol size={6}>
                    <RelationHeader />
                    {leftHalf.map((rel, index) => (
                        <RelationRow key={index} relation={rel} onClick={props.onClickRelation} />
                    ))}
                </GridCol>
                {rightHalf.length > 0 && (
                    <GridCol size={6}>
                        <RelationHeader />
                        {rightHalf.map((rel, index) => (
                            <RelationRow key={index} relation={rel} onClick={props.onClickRelation} />
                        ))}
                    </GridCol>
                )}
            </Grid>
        </Box>
    );
}
