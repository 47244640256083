const appConfig = import.meta.env;

export const msAzureADIdentityApi = 'https://login.microsoftonline.com';

export const msalConfig = {
    /**
     * This config is taken from the Azure portal for the app registration / the respective terraform module in rh-frontend-infra:
     * fxt.pricing.gr.ridehub(-dev)
     */
    auth: {
        clientId: appConfig.VITE_CLIENT_ID,
        authority: `${msAzureADIdentityApi}/${appConfig.VITE_TENANT_ID}`,
        redirectUri: `${document.location.origin}/login`,
        postLogoutRedirectUri: `${appConfig.VITE_BASE_URL}/login`,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};
