import {
    Header,
    HeaderBrand,
    HeaderNavigation,
    HeaderSubNavigation,
    HeaderUserWidget,
    HeaderWidgets,
    NavItem,
} from '@flixbus/honeycomb-react';

import { NavLink } from 'react-router-dom';
import React from 'react';
import styles from './rh-header.module.scss';

/* eslint-disable-next-line */
export interface RhHeaderProps {
    displayName?: string;
    versionTag: string;
    countrySelect: React.ReactNode;
}

export function RhHeader(props: RhHeaderProps) {
    return (
        <Header fullwidth>
            <HeaderBrand
                extraClasses={styles.headerLogo}
                alt="RideHub"
                href="/"
                RouterLink={NavLink}
                src="/ridehub_logo.svg"
                appearance="tall"
                data-dd-action-name="Go to Search"
            />
            {props.displayName ? (
                <HeaderNavigation aria-label="Main">
                    <NavItem RouterLink={NavLink} to="/" data-dd-action-name="Go to Search">
                        Search Ride
                    </NavItem>
                </HeaderNavigation>
            ) : (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (null as any)
            )}
            <HeaderWidgets>
                <div className={styles.headerWidgets}>
                    {props.countrySelect}
                    {!!props.displayName && (
                        <HeaderUserWidget
                            data-dd-privacy="mask"
                            data-dd-action-name="Open Profile Menu"
                            subMenu={
                                <HeaderSubNavigation alignment="left" id="user-sub-menu" data-dd-privacy="allow">
                                    <NavItem href="/logout">Logout</NavItem>
                                    <NavItem>Version: {props.versionTag}</NavItem>
                                </HeaderSubNavigation>
                            }
                        >
                            {props.displayName}
                        </HeaderUserWidget>
                    )}
                </div>
            </HeaderWidgets>
        </Header>
    );
}

export default RhHeader;
