import { RideDetails, Segment } from '../api-interfaces';

import { ParsingError } from '../types/custom-errors';
import { Temporal } from '@js-temporal/polyfill';

/**
 * This Module contains functions to transform data.
 * @param data as received from the backend
 * @returns slight transformations as needed on frontend such as parsing dates.
 * Dates are parsed with the help of the new Temporal API (currently Stage 3 Proposal),
 * which at this time only exists as a polyfill, but is documented here:
 * https://tc39.es/proposal-temporal/docs/index.html
 */

export function castToTemporalsAndApplyTimezoneOffset(segment: {
    departure_time: string;
    departure_timezone: string;
    arrival_time: string;
    arrival_timezone: string;
}) {
    const transformed = { ...segment } as unknown as Segment;
    // We need to display the local time of each stop.
    transformed.departure_time = Temporal.PlainDateTime.from(segment.departure_time)
        .toZonedDateTime('UTC')
        .withTimeZone(segment.departure_timezone);
    transformed.arrival_time = Temporal.PlainDateTime.from(segment.arrival_time)
        .toZonedDateTime('UTC')
        .withTimeZone(segment.arrival_timezone);
    return transformed;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformRideData(data: any): RideDetails {
    if (!data.ride_uuid) {
        if (data.error) {
            throw new ParsingError(data.error);
        } else {
            throw new ParsingError('Invalid data received');
        }
    }
    if (data.last_optimized_at) {
        data.last_optimized_at = Temporal.Instant.from(data.last_optimized_at);
    } else {
        data.last_optimized_at = undefined;
    }
    for (let i = 0; i <= data.segments.length - 1; i++) {
        data.segments[i] = castToTemporalsAndApplyTimezoneOffset(data.segments[i]);
    }
    return data;
}
