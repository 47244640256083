import {
    Area,
    Brush,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { RouteChangeReferenceLines, X_AXIS_SIZING } from '../../commons-chart/commons-chart';

import { Box } from '@flixbus/honeycomb-react';
import React from 'react';
import { Temporal } from '@js-temporal/polyfill';
import { TurboOutputRelationLevel } from '@ridehub/data-ride';
import chartStyles from '../../ui-charts.module.scss';
import { printDate } from '@ridehub/util-temporal';
import styles from './forecast-chart.module.scss';
import { useLocalization } from '@ridehub/feat-localization';

const TRACE_COLORS = {
    primary: styles.primary_color,
    efficient: styles.efficient_color,
    inefficient: styles.inefficient_color,
    unscaled: styles.unscaled_color,
    unselected: styles.unselected_color,
    brush: styles.brush_color,
};

const contextKeys = [
    'pre_booking_day',
    'max_demand',
    'lowest_efficient_demand',
    'min_demand',
    'nominal_price',
    'efficient',
    'nominal_price_localized',
    'route_changed',
    'lowest_efficient_demand_unscaled',
    'scaling_factor',
];

interface ChartDataPoint {
    [key: string]: number | boolean | Record<string, number> | Record<string, boolean> | undefined;
    pre_booking_day: number;
    route_changed: boolean;
    max_demand: number;
    lowest_efficient_demand: number;
    min_demand: number;
    nominal_price_localized: Record<string, number>;
    efficient: Record<string, boolean>;
    lowest_efficient_demand_unscaled: number | undefined;
    scaling_factor: number | undefined;
}

export interface ForecastChartProps {
    rideDeparture: Temporal.ZonedDateTime | undefined;
    relationsData: Array<TurboOutputRelationLevel> | undefined;
    chartSyncId: string;
    preBookingDayAxisEnd: number | string;
    preBookingDayAxisTicks: Array<number>;
    onPreBookingDayZoom: (newIndex: { startIndex: number; endIndex: number }) => void;
    fareMillReferenceLine: () => JSX.Element | null;
}

export function ForecastChart({
    rideDeparture,
    relationsData,
    chartSyncId,
    preBookingDayAxisEnd,
    preBookingDayAxisTicks,
    onPreBookingDayZoom,
    fareMillReferenceLine,
}: ForecastChartProps) {
    const { selectedCurrency, localizeCurrency, printWithLocalUnit } = useLocalization();

    const [showScalingFactors, setShowcalingFactors] = React.useState<boolean>(false);
    const [hoverOverScalingFactor, setHoverOverScalingFactor] = React.useState<boolean>(false);

    const { chartData } = React.useMemo(
        function prepareChartData(): { chartData: Array<ChartDataPoint> } {
            if (!relationsData) {
                return { chartData: [] };
            }

            const chartData: Array<ChartDataPoint> = relationsData.map((entry) => {
                const d: ChartDataPoint = {
                    pre_booking_day: entry.pre_booking_day,
                    route_changed: entry.route_changed,
                    max_demand: 0,
                    min_demand: Infinity,
                    lowest_efficient_demand: entry.remaining_forecast_per_price_label[0].remaining_forecast,
                    nominal_price_localized: {},
                    efficient: {},
                    scaling_factor: undefined,
                    lowest_efficient_demand_unscaled: undefined,
                };
                const lowest_efficient_fare_localized = localizeCurrency(entry.lowest_efficient_fare_eur);
                let lowestEfficient = false;
                entry.remaining_forecast_per_price_label.forEach((entryData) => {
                    const efficient = localizeCurrency(entryData.nominal_price_eur) >= lowest_efficient_fare_localized;
                    d.max_demand = Math.max(d.max_demand, entryData.remaining_forecast);
                    d.min_demand = Math.min(d.min_demand, entryData.remaining_forecast);
                    d.lowest_efficient_demand = lowestEfficient
                        ? d.lowest_efficient_demand
                        : entryData.remaining_forecast;
                    lowestEfficient = lowestEfficient ? lowestEfficient : efficient;
                    d[entryData.price_label] = entryData.remaining_forecast;
                    d.nominal_price_localized[entryData.price_label] = localizeCurrency(entryData.nominal_price_eur);
                    d.efficient[entryData.price_label] = efficient;
                    d.lowest_efficient_demand_unscaled = entry.scaling_factor
                        ? d.lowest_efficient_demand / entry.scaling_factor
                        : undefined;
                    d.scaling_factor = entry.scaling_factor;
                });
                d.max_demand = d.max_demand - d.lowest_efficient_demand;
                d.lowest_efficient_demand = d.lowest_efficient_demand - d.min_demand;
                return d;
            });

            const dataKeysSet: Set<string> = new Set(chartData.map((entry) => Object.keys(entry)).flat());
            contextKeys.forEach((key) => dataKeysSet.delete(key));

            return { chartData: chartData.sort((a, b) => a.pre_booking_day - b.pre_booking_day) };
        },
        // Instances of the localize functions do not differ between renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [relationsData, selectedCurrency]
    );

    const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
        if (props.active && props.payload && props.payload.length) {
            const preBookingMinutes = -Math.round(24 * 60 * props.label);
            const optimizedAt =
                rideDeparture && printDate(rideDeparture.subtract({ minutes: preBookingMinutes }), false);
            const tooltipData = chartData.find((r) => r.pre_booking_day === props.label);
            if (tooltipData) {
                const lastdataPoint = chartData[chartData.length - 1];
                const isLastDataPoint = tooltipData.pre_booking_day === lastdataPoint.pre_booking_day;
                const isDepartedRide = lastdataPoint.pre_booking_day === 0;
                return (
                    <Box extraClasses={chartStyles.tooltipContainer} highlighted>
                        {isLastDataPoint ? (
                            isDepartedRide ? (
                                <h2>At ride departure</h2>
                            ) : (
                                <h2>Now</h2>
                            )
                        ) : (
                            <h2>{`${optimizedAt} (${-props.label.toFixed(2)} days before departure)`}</h2>
                        )}
                        {Object.entries(tooltipData).map(([key, value]) => {
                            if (contextKeys.includes(key)) {
                                return null;
                            }

                            return (
                                <p
                                    key={`tooltip-fare-${key}`}
                                    style={{
                                        color: tooltipData.efficient[key]
                                            ? TRACE_COLORS.primary
                                            : TRACE_COLORS.inefficient,
                                    }}
                                >
                                    {`${key} (${printWithLocalUnit(
                                        'currency',
                                        tooltipData.nominal_price_localized[key]
                                    )}): `}
                                    {showScalingFactors && tooltipData.scaling_factor ? (
                                        <span className={chartStyles.value}>
                                            {(Number(value) / tooltipData.scaling_factor).toFixed(2)}
                                            {' / '}
                                        </span>
                                    ) : null}
                                    <span className={chartStyles.value}>{Number(value).toFixed(2)}</span> seats
                                </p>
                            );
                        })}
                        {showScalingFactors && (
                            <p>
                                Scaling factor:{' '}
                                <span className={chartStyles.value}>
                                    {tooltipData.scaling_factor ? tooltipData.scaling_factor.toFixed(2) : '-'}
                                </span>
                            </p>
                        )}
                        {tooltipData.route_changed && (
                            <p>
                                <span className={chartStyles.value}>Route changed</span>
                            </p>
                        )}
                    </Box>
                );
            }
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={340}>
            <ComposedChart
                className={chartStyles.chart}
                syncId={chartSyncId}
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 40,
                }}
            >
                <CartesianGrid className={chartStyles.cartesianGrid} vertical={false} />
                <XAxis
                    dataKey="pre_booking_day"
                    type="number"
                    height={X_AXIS_SIZING.xAxisHeight}
                    domain={[(dataMin: number) => Math.floor(dataMin), preBookingDayAxisEnd]}
                    ticks={preBookingDayAxisTicks}
                    tickFormatter={(value: number) => (-value).toFixed(0)}
                >
                    <Label value="Prebooking day" dy={X_AXIS_SIZING.xAxisLabelDy} />
                </XAxis>
                <YAxis
                    orientation="left"
                    type="number"
                    domain={[0, (dataMax: number) => Math.ceil(dataMax)]}
                    allowDecimals={false}
                >
                    <Label value="Seats" angle={-90} position="insideLeft" offset={0} dx={10} dy={0} />
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                    align="right"
                    verticalAlign="top"
                    height={36}
                    payload={[
                        { type: 'line', value: 'Lowest Efficient', color: TRACE_COLORS.efficient },
                        { type: 'square', value: 'Efficient', color: TRACE_COLORS.efficient },
                        { type: 'square', value: 'Inefficient', color: TRACE_COLORS.inefficient },
                        {
                            type: 'line',
                            value: 'Lowest Efficient (w/o safety net)',
                            color: showScalingFactors ? TRACE_COLORS.unscaled : TRACE_COLORS.unselected,
                        },
                    ]}
                    data-dd-action-name="Show Scaling Factor"
                    onClick={(payload) => {
                        if (payload.value === 'Lowest Efficient (w/o safety net)') {
                            setShowcalingFactors(!showScalingFactors);
                        }
                    }}
                    onMouseEnter={(payload) => {
                        if (payload.value === 'Lowest Efficient (w/o safety net)') {
                            setHoverOverScalingFactor(true);
                        }
                    }}
                    onMouseLeave={(payload) => {
                        if (payload.value === 'Lowest Efficient (w/o safety net)') {
                            setHoverOverScalingFactor(false);
                        }
                    }}
                />
                {fareMillReferenceLine()}
                {RouteChangeReferenceLines({ routeChangesData: chartData })}
                <Area
                    dataKey="min_demand"
                    stackId="stackedAreaId"
                    stroke={TRACE_COLORS.efficient}
                    strokeWidth={1}
                    fillOpacity={0}
                />
                <Area
                    dataKey="lowest_efficient_demand"
                    stackId="stackedAreaId"
                    dot={{
                        stroke: TRACE_COLORS.efficient,
                        strokeWidth: 2,
                        fill: 'white',
                        fillOpacity: 1,
                    }}
                    stroke={TRACE_COLORS.efficient}
                    strokeWidth={2}
                    fill={TRACE_COLORS.efficient}
                    fillOpacity={0.4}
                />
                <Area
                    dataKey="max_demand"
                    stackId="stackedAreaId"
                    stroke={TRACE_COLORS.inefficient}
                    strokeWidth={1}
                    fill={TRACE_COLORS.inefficient}
                    fillOpacity={0.4}
                />
                <Line
                    hide={!showScalingFactors}
                    dataKey="lowest_efficient_demand_unscaled"
                    stroke={TRACE_COLORS.unscaled}
                    strokeWidth={2}
                    strokeOpacity={hoverOverScalingFactor ? 0.5 : 1.0}
                    dot={false}
                    isAnimationActive={false}
                    connectNulls
                />
                <Brush
                    data-dd-action-name="Zoom in Chart Brush"
                    dataKey="pre_booking_day"
                    height={X_AXIS_SIZING.brushHeight}
                    tickFormatter={() => ''}
                    stroke={TRACE_COLORS.brush}
                    onChange={onPreBookingDayZoom as any}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}
