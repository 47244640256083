export interface IAppConfig {
    VITE_ENVIRONMENT: 'stg' | 'prod' | 'local';
    VITE_API_URL: string;
    VITE_BACKEND: 'mock' | 'local' | 'stg' | 'prod';
    VITE_AZURE_AD_API_ID: string;
    VITE_BASE_URL: string;
    VITE_CLIENT_ID: string;
    VITE_TENANT_ID: string;
    VITE_DATADOG_APPLICATION_ID: string;
    VITE_DATADOG_CLIENT_TOKEN: string;
    VITE_DATADOG_SERVICE: string;
    VITE_DATADOG_SITE: string;
    VITE_VERSION_TAG: string;
}

// Would be nice to verify this at build time
export const appConfig = import.meta.env as unknown as IAppConfig;
if (import.meta.env.MODE === 'development') {
    appConfig.VITE_API_URL = `${appConfig.VITE_API_URL}/api-${appConfig.VITE_BACKEND}`;
    appConfig.VITE_VERSION_TAG = 'dev-build';
}
