import React from 'react';
import styles from './labeled-value.module.scss';

interface LabeledValueProps {
    label: string;
    value: string | React.ReactNode | undefined;
    compact?: boolean;
    size?: 'sm' | 'md' | 'lg';
}

export function LabeledValue({ label, value, compact = false, size = 'md' }: LabeledValueProps) {
    if (!value) {
        return null;
    }

    let kpiLabelClass = styles.kpiLabel;
    let kpiValueClass = styles.kpiValue;
    let kpiCompactClass = styles.kpiCompact;
    switch (size) {
        case 'sm':
            kpiLabelClass = kpiLabelClass + ' ' + styles.kpiSmall;
            kpiValueClass = kpiValueClass + ' ' + styles.kpiSmall;
            kpiCompactClass = kpiCompactClass + ' ' + styles.kpiSmall;
            break;
        case 'lg':
            kpiLabelClass = kpiLabelClass + ' ' + styles.kpiLarge;
            kpiValueClass = kpiValueClass + ' ' + styles.kpiLarge;
            kpiCompactClass = kpiCompactClass + ' ' + styles.kpiLarge;
            break;
    }

    if (compact) {
        return (
            <div className={styles.kpi}>
                <p className={kpiCompactClass}>
                    {`${label}: `}
                    {value}
                </p>
            </div>
        );
    } else {
        return (
            <div className={styles.kpi}>
                <h2 className={kpiLabelClass}>{label}</h2>
                {typeof value === 'string' ? (
                    <p className={kpiValueClass}>{value}</p>
                ) : (
                    <div className={kpiValueClass}>{value}</div>
                )}
            </div>
        );
    }
}
