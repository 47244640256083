import React, { useCallback, useEffect, useRef } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useLocation } from 'react-router-dom';

export interface SLICtx {
    reportSliGoodEvent: () => void;
    reportSliBadEvent: () => void;
}

export const SLIContext = React.createContext<SLICtx | null>(null);

interface SLIEvents {
    [eventKey: string]: { isVisited: boolean; isGood: boolean; hasError: boolean };
}

function reportPageLoadAction(eventType: 'started' | 'success' | 'error') {
    datadogRum.addAction('SLIPageLoad', { eventType });
}

export function SLIContextProvider(props: {
    children: React.ReactNode;
    onSLIEvent?: (eventType: 'started' | 'success' | 'error') => void;
}) {
    const reportEvent = props.onSLIEvent ? props.onSLIEvent : reportPageLoadAction;
    const location = useLocation();
    const sliEvents = useRef<SLIEvents>({});

    function isPathRelevantForSLIs(urlPath: string) {
        return urlPath.startsWith('/rides');
    }

    useEffect(() => {
        function markUrlPathAsVisited(urlPath: string) {
            const eventNotYetReported = sliEvents.current[urlPath] === undefined;
            if (eventNotYetReported) {
                sliEvents.current[urlPath] = { isVisited: true, isGood: false, hasError: false };
                reportEvent('started');
            } else if (sliEvents.current[urlPath].isGood && !sliEvents.current[urlPath].isVisited) {
                sliEvents.current[urlPath].isVisited = true;
                reportEvent('started');
            } else if (sliEvents.current[urlPath].hasError && !sliEvents.current[urlPath].isVisited) {
                sliEvents.current[urlPath].isVisited = true;
                reportEvent('started');
            }
        }

        if (isPathRelevantForSLIs(location.pathname)) {
            markUrlPathAsVisited(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const reportSliGoodEvent = useCallback(
        function reportSliEvent() {
            if (isPathRelevantForSLIs(location.pathname)) {
                const eventNotYetReported = sliEvents.current[location.pathname] === undefined;
                if (eventNotYetReported) {
                    sliEvents.current[location.pathname] = { isVisited: false, isGood: true, hasError: false };
                    reportEvent('success');
                } else if (
                    sliEvents.current[location.pathname].isVisited &&
                    !sliEvents.current[location.pathname].isGood
                ) {
                    sliEvents.current[location.pathname].isGood = true;
                    reportEvent('success');
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    );

    const reportSliBadEvent = useCallback(
        function reportSliEvent() {
            // Only report error if we know of a success event before (to cancel it out)
            if (isPathRelevantForSLIs(location.pathname)) {
                const eventIsReported = sliEvents.current[location.pathname] !== undefined;
                if (
                    eventIsReported &&
                    !sliEvents.current[location.pathname].hasError &&
                    sliEvents.current[location.pathname].isGood
                ) {
                    sliEvents.current[location.pathname].hasError = true;
                    reportEvent('error');
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    );

    return (
        <SLIContext.Provider value={{ reportSliGoodEvent, reportSliBadEvent }}>{props.children}</SLIContext.Provider>
    );
}

class OutsideContextError extends Error {}

export function useSLIContext() {
    const context = React.useContext(SLIContext);
    if (!context) {
        throw new OutsideContextError('useSLIContext must be used within a SLIContextProvider');
    }
    return context;
}
