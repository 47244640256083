import {
    Configuration,
    InteractionRequiredAuthError,
    InteractionStatus,
    PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import React, { useEffect, useMemo } from 'react';

import { Spinner } from '@flixbus/honeycomb-react';
import styles from './background-auth.module.scss';
import { useApiConfig } from '@ridehub/data-ride';

export interface BackgroundAuthProps {
    children: React.ReactNode;
    msalConfig: Configuration;
    onError: (error: Error | unknown) => void;
}

/**
 * The purpose of this component is to execute authentication code
 * in 'background' / invisible without flashing the Login component
 * in case the user already has an active AAD session.
 */
export function BackgroundAuth(props: BackgroundAuthProps) {
    const apiConfig = useApiConfig();
    const RIDES_API_SCOPE = `${apiConfig.azureAdApiId}/OAuth.Rides.Read`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const msalInstance = useMemo(() => new PublicClientApplication(props.msalConfig), []);

    useEffect(() => {
        async function loginSilently() {
            try {
                // This check is not absolutely correct, but makes sure to execute sso only once.
                const isSignedOut = msalInstance.getAllAccounts().length === 0;
                await msalInstance.initialize();
                if (isSignedOut) {
                    // This is only meant to automatically refresh access tokens.
                    await msalInstance.ssoSilent({
                        scopes: [RIDES_API_SCOPE],
                        redirectUri: apiConfig.blankRedirectUri,
                    });
                    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    // This is expected when the user has no active AAD session.
                    // Users will be redirected to a login page from within the app.
                    return;
                } else {
                    props.onError(error);
                }
            }
        }
        loginSilently();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MsalProvider instance={msalInstance}>
            <AuthProgress>{props.children}</AuthProgress>
        </MsalProvider>
    );
}

export function AuthProgress(props: { children: React.ReactNode }) {
    const { inProgress } = useMsal();

    if (inProgress !== InteractionStatus.None) {
        return (
            <div className={styles.loadingIndicator}>
                <Spinner size="sm" />
                <span>Loading...</span>
            </div>
        );
    }
    return props.children;
}
