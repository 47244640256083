export class CustomError extends Error {
    code: number;
    constructor(message?: string, code?: number) {
        super(message);
        this.name = this.constructor.name;
        this.code = code || 0;
    }
}
export class UserInputError extends CustomError {
    constructor(message?: string, code = 422) {
        super(message, code);
    }
}
export class SearchError extends CustomError {
    constructor(message?: string, code = 404) {
        super(message, code);
    }
}
export class BackendError extends CustomError {
    constructor(message?: string, code = 500) {
        super(message, code);
    }
}
export class ParsingError extends CustomError {
    constructor(message?: string, code = 409) {
        super(message, code);
    }
}
export class InputError extends CustomError {
    constructor(message?: string, code = 400) {
        super(message, code);
    }
}
