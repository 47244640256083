import { Box, CloseButton, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';

import { LabeledValue } from '../labeled-value/labeled-value';
import React from 'react';
import { Temporal } from '@js-temporal/polyfill';
import styles from './relation-kpi-box.module.scss';

export function PriceViewerLink(props: {
    fromCityName: string;
    toCityName: string;
    fromCityUuid: string;
    toCityUuid: string;
    departureDate: Temporal.ZonedDateTime;
    arrivalDate: Temporal.ZonedDateTime;
}) {
    const arrivalDate = props.arrivalDate.toPlainDate();
    const departureDate = props.departureDate.toPlainDate();
    const today = Temporal.Now.plainDate(props.arrivalDate.calendarId);
    if (Temporal.PlainDate.compare(arrivalDate, today) === -1) {
        return null;
    }
    const baseUrl = 'https://price-viewer.amt.flix.tech';
    const searchPath = 'daily';
    const priceViewerUrl = new URL(searchPath, baseUrl);
    priceViewerUrl.searchParams.append('from', props.fromCityUuid);
    priceViewerUrl.searchParams.append('to', props.toCityUuid);
    priceViewerUrl.searchParams.append('from_name', props.fromCityName);
    priceViewerUrl.searchParams.append('to_name', props.toCityName);
    const datePrintOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    priceViewerUrl.searchParams.append('start_date', departureDate.toLocaleString('de-DE', datePrintOptions));
    priceViewerUrl.searchParams.append('end_date', departureDate.toLocaleString('de-DE', datePrintOptions));
    priceViewerUrl.searchParams.append('currency', 'EUR');
    priceViewerUrl.searchParams.append('trip_type', 'all');
    priceViewerUrl.searchParams.append('travel_type', 'all');
    return (
        <div className={styles.linkContainer}>
            <a href={priceViewerUrl.toString()} target="_blank" rel="noreferrer">
                PriceViewer{' '}
                <Icon InlineIcon={IconNewTab} aria-label="open-new-tab" style={{ verticalAlign: 'sub' }} size={3} />
            </a>
        </div>
    );
}

export function FareMillLink(props: { fromCityUuid: string; toCityUuid: string }) {
    const baseUrl = 'https://fare-mill.amt.flix.tech';
    const searchPath = 'price-list';
    const fareMillUrl = new URL(searchPath, baseUrl);
    fareMillUrl.searchParams.append('from_type', 'CITY');
    fareMillUrl.searchParams.append('to_type', 'CITY');
    fareMillUrl.searchParams.append('from_value', props.fromCityUuid);
    fareMillUrl.searchParams.append('to_value', props.toCityUuid);
    fareMillUrl.searchParams.append('details_view', 'PRICE_UNITS');
    return (
        <div className={styles.linkContainer}>
            <a href={fareMillUrl.toString()} target="_blank" rel="noreferrer">
                FareMill{' '}
                <Icon InlineIcon={IconNewTab} aria-label="open-new-tab" style={{ verticalAlign: 'sub' }} size={3} />
            </a>
        </div>
    );
}

interface RelationKpiBoxProps {
    fromStop: string;
    toStop: string;
    fromCity: string;
    toCity: string;
    maxLoad: number;
    demandMaxLoad?: number;
    revenue: string;
    yield: string;
    distance: string;
    durationMin: number;
    onClose: () => void;
    priceViewerLink?: JSX.Element;
    fareMillLink?: JSX.Element;
}

export function RelationKpiBox(props: RelationKpiBoxProps) {
    return (
        <Box extraClasses={styles.container}>
            <Grid>
                <GridCol size={11}>
                    <div className={styles.row}>
                        <h2>Relation: </h2>
                        <h1>{props.fromCity}</h1>
                        <h2 className={styles.fontSizeXs}> at {props.fromStop}</h2>
                        <h1> - {props.toCity}</h1>
                        <h2 className={styles.fontSizeXs}> at {props.toStop}</h2>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Max Load" value={`${(props.maxLoad * 100).toFixed(0)}%`} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue
                                size="lg"
                                label="Max Demand Load"
                                value={formatCondPercentage(props.demandMaxLoad)}
                            />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Distance" value={props.distance} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Duration" value={formatMinutes(props.durationMin)} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Revenue" value={props.revenue} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Yield" value={props.yield} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Fare Profile" value={props.fareMillLink} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Offered price" value={props.priceViewerLink} />
                        </div>
                    </div>
                </GridCol>
                <GridCol size={1} extraClasses={styles.posRelative}>
                    <CloseButton
                        extraClasses={styles.closeButton}
                        onClick={props.onClose}
                        aria-label="Close"
                        data-dd-action-name="Close Relation View"
                    />
                </GridCol>
            </Grid>
        </Box>
    );
}

interface SegmentKpiBoxProps {
    fromStop: string;
    toStop: string;
    fromCity: string;
    toCity: string;
    load: number;
    demandLoad?: number;
    distance: string;
    durationMin: number;
    onClose: () => void;
}

export function SegmentKpiBox(props: SegmentKpiBoxProps) {
    return (
        <Box extraClasses={styles.container}>
            <Grid>
                <GridCol>
                    <div className={styles.row}>
                        <h2>Segment: </h2>
                        <h1>{props.fromCity}</h1>
                        <h2 className={styles.fontSizeXs}> at {props.fromStop}</h2>
                        <h1> - {props.toCity}</h1>
                        <h2 className={styles.fontSizeXs}> at {props.toStop}</h2>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Current Load" value={`${(props.load * 100).toFixed(0)}%`} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue
                                size="lg"
                                label="Demand Load"
                                value={formatCondPercentage(props.demandLoad)}
                            />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Distance" value={props.distance} />
                        </div>
                        <div className={styles.kpi}>
                            <LabeledValue size="lg" label="Duration" value={formatMinutes(props.durationMin)} />
                        </div>
                    </div>
                </GridCol>
                <GridCol size={1} extraClasses={styles.posRelative}>
                    <CloseButton
                        extraClasses={styles.closeButton}
                        onClick={props.onClose}
                        aria-label="Close"
                        data-dd-action-name="Close Segment View"
                    />
                </GridCol>
            </Grid>
        </Box>
    );
}

function formatCondPercentage(value: number | undefined): string {
    return value === null || value === undefined ? '--' : `${(value * 100).toFixed(0)}%`;
}

function formatMinutes(value: number): string {
    if (value < 60) {
        return `${value} Min`;
    } else {
        return `${Math.floor(value / 60)} H ${value % 60} Min`;
    }
}
