import { Button, Grid, GridCol, Input, Spinner } from '@flixbus/honeycomb-react';
import { Icon, IconClose, IconMagnifier } from '@flixbus/honeycomb-icons-react';
import React, { useEffect } from 'react';

import { RideDataErrorBox } from '@ridehub/ui-widgets';
import styles from './feat-ride-search.module.scss';
import { useRideSearch } from '@ridehub/data-ride';
import { validate } from 'uuid';

export interface FeatRideSearchProps {
    onSeeRide: (rideId: string) => void;
}

export function FeatRideSearch(props: FeatRideSearchProps) {
    const [rideId, setRideId] = React.useState<string>('');
    const [submitted, setSubmitted] = React.useState<boolean>(false);

    function submitSearchOnEnter(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            setSubmitted(true);
        }
    }

    function resetSearch() {
        setRideId('');
        setSubmitted(false);
    }

    function SearchResults(props: { rideId: string; onSeeRide: (rideId: string) => void; onClearSearch: () => void }) {
        const { isPending, error, data } = useRideSearch(props.rideId);

        useEffect(() => {
            if (data && data.exists) {
                props.onSeeRide(props.rideId);
            }
        }, [props, data]);

        if (isPending) {
            return (
                <Button appearance="primary" extraClasses={styles.submitButton}>
                    <Spinner size="sm" />
                </Button>
            );
        } else if (error) {
            return (
                <div>
                    <Button appearance="primary" extraClasses={styles.submitButton} onClick={props.onClearSearch}>
                        Clear
                    </Button>
                    <RideDataErrorBox error={error} extraClasses={styles.techErrorBox} />
                </div>
            );
        } else if (data) {
            if (data.exists) {
                // We will navigate to the ride dashboard inside useEffect
                return null;
            } else {
                return (
                    <div>
                        <Button appearance="primary" extraClasses={styles.submitButton} onClick={props.onClearSearch}>
                            Clear
                        </Button>
                        <span className={styles.infoText}>A ride with this uuid could not be found.</span>
                    </div>
                );
            }
        }
    }

    const showResults = submitted && validate(rideId);
    return (
        <Grid extraClasses={styles.searchContainer}>
            <GridCol size={4} push={1}>
                <Input
                    id="ride-search"
                    aria-label="Search Ride"
                    label="Ride UUID"
                    placeholder="Enter Ride UUID"
                    iconLeft={<Icon InlineIcon={IconMagnifier} />}
                    value={rideId}
                    onChange={(e) => setRideId(e.target.value)}
                    onKeyDown={submitSearchOnEnter as unknown as React.KeyboardEventHandler<HTMLInputElement>}
                    iconRight={
                        <Button aria-label="Clear destination field" onClick={resetSearch}>
                            <Icon InlineIcon={IconClose} />
                        </Button>
                    }
                    valid={submitted || validate(rideId) ? validate(rideId) : undefined}
                    info={validate(rideId) ? 'Valid uuid.' : undefined}
                    infoError={'Please enter a valid ride uuid.'}
                />
            </GridCol>
            <GridCol size={2}>
                {showResults ? (
                    <SearchResults rideId={rideId} onSeeRide={props.onSeeRide} onClearSearch={resetSearch} />
                ) : (
                    <Button
                        appearance="primary"
                        extraClasses={styles.submitButton}
                        onClick={() => setSubmitted(true)}
                        data-dd-action-name="View Search Results"
                    >
                        View
                    </Button>
                )}
            </GridCol>
        </Grid>
    );
}

export default FeatRideSearch;
